/* eslint-disable mastery/known-imports */
import { MODE } from '@env';
import { parseAbsolute, TimeFields } from '@internationalized/date';
import {
  addDays as addDaysFns,
  differenceInCalendarDays,
  differenceInMilliseconds,
  endOfDay,
  // eslint-disable-next-line no-restricted-imports
  format,
  isAfter,
  isBefore,
  isValid,
  parse,
  startOfDay,
  subDays as subDaysFns,
} from 'date-fns';
import {
  compact,
  debounce,
  isNil,
  isNumber,
  isString,
  isUndefined,
  last,
  memoize,
  range,
  throttle,
  uniq,
} from 'lodash-es';
import timezones from 'timezones.json';
import { MergeExclusive } from 'type-fest';
import { CURRENT_LOCALE } from './util';

/** @deprecated Do not use! Use getDatetimeValue instead, which supports internationalization. */
export const dateFnsFormat = format;

export const MaxDate = new Date(8640000000000000);

export const millisToIntlSetObj = (ms: number): TimeFields => {
  const secs = ms / 1000;
  const hour = Math.floor(secs / 3600);
  const minute = Math.floor((secs % 3600) / 60);
  const second = Math.floor(secs % 60);
  const millisecond = Math.floor(ms % 1000);
  return {
    hour,
    minute,
    second,
    millisecond,
  };
};

interface DatetimeWithTimezoneInput {
  readonly timezone: string;
  readonly value: string;
}

export type IANATimezones =
  | 'America/Anchorage'
  | 'America/Los_Angeles'
  | 'America/Phoenix'
  | 'America/Denver'
  | 'America/Chicago'
  | 'America/New_York'
  | 'UTC'
  | 'Asia/Tokyo'
  | string;

export const currentTZ: IANATimezones =
  (Intl.DateTimeFormat().resolvedOptions().timeZone as IANATimezones) ||
  'America/Chicago';

export const getTimezone = memoize((str: string): string => {
  let tz = str;
  if (tz === 'UTC') {
    return 'Etc/UTC';
  }
  // The following case is for legacy timezones like UTC or CDT. We want values like America/Chicago instead
  if (tz.length < 5) {
    tz = timezones.find((obj) => obj.abbr === str)?.utc[0] || '';
  }
  return tz;
});

export const getDateFromDatetime = (
  str: string,
  /** Use leading zeros for month and day, like 02/03. Defaults to true */
  rawLeadingZero?: boolean,
  withYear?: boolean
): string => {
  const useLeadingZero = rawLeadingZero === undefined ? true : rawLeadingZero;
  let result = str;
  if (str.match(/^\d?\d\/\d?\d\/\d\d\d\d/)) {
    result = withYear
      ? str.replace(/^(\d?\d\/\d?\d\/\d\d\d\d).*/, '$1')
      : str.replace(/^(\d?\d\/\d?\d).*/, '$1');
  } else if (str.match(/^\d?\d\/\d?\d\/\d\d/)) {
    result = withYear
      ? str.replace(/^(\d?\d\/\d?\d\/\d\d)/, '$1')
      : str.replace(/(.*\d?\d\/\d?\d)\/\d\d/, '$1');
  }
  if (useLeadingZero) {
    result = result
      .split('/')
      .map((n) => n.padStart(2, '0'))
      .join('/');
  }
  return result;
};

/** @deprecated If you need to display a date to a user, use `DatetimeValue` component or `getDatetimeValue` helper. */
export const DEPRECATEDgetTimezoneAwareDateFormattedMMDDYY = (
  dateObj: Date,
  tz?: string
): string => {
  return dateObj.toLocaleString('en-US', {
    timeZone: tz ? getTimezone(tz) : undefined,
    hour12: false,
    dateStyle: 'short',
  } as fixMe);
};

/** @deprecated Use server utilities like `serverUtilityUTCDateToLocal` for constructing queries or mutations. If you need to display a date to a user, use `DatetimeValue` component or `getDatetimeValue` helper. */
export const DEPRECATEDgetDateFromLocaleDatetime = (
  dateObj: Date,
  tz?: string,
  withYear?: boolean
): string => {
  return getDateFromDatetime(
    DEPRECATEDgetTimezoneAwareDateFormattedMMDDYY(dateObj, tz),
    undefined,
    withYear
  );
};

/** NOT FOR DISPLAYING TO USERS. Create a date string from a date object in a format (defaults to 'MM/DD/YYYY') that is timezone aware. ie takes in UTC date and turns it into a localized date string based on tz. */
export const serverUtilityUTCDateToLocal = (
  str: Maybe<string | Date>,
  tz: Maybe<string>,
  format: 'MM/DD/YYYY' | 'YYYY-MM-DD' = 'MM/DD/YYYY'
): string | undefined => {
  if (!str || !tz) {
    return undefined;
  }
  const dateObj = new Date(str);
  if (isValid(dateObj)) {
    const tt = parseAbsolute(dateObj.toISOString(), tz ?? currentTZ);

    if (format === 'MM/DD/YYYY') {
      return [tt.month, tt.day, tt.year]
        .map((str) => str.toString().padStart(2, '0'))
        .join('/');
    } else if (format === 'YYYY-MM-DD') {
      return [tt.year, tt.month, tt.day]
        .map((str) => str.toString().padStart(2, '0'))
        .join('-');
    }
  }
  return undefined;
};
/** NOT for user display. Use `getDatetimeValue` for rendering dates + times. This utility should only be used to send data to an API (ie date filtering on a query). */
export const serverUtilityFormatDateFromCurrentTimezone = (
  date: Date,
  strFormat: 'yyyy-MM-dd'
): string => {
  return format(date, strFormat);
};

/** @deprecated Use `getDatetimeValue` for rendering to user. Use `serverUtilityUTCDateToLocal` to create a date string that is for the server in that is always in `MM/DD/YYYY` format. */
export const DEPRECATEDutcDatetimeToLocalDate = (
  /** The utcDatetime string like 2019-12-18T21:43:10.587Z */
  str: Maybe<string | Date>,
  /** The timezone like America/Chicago */
  tz: Maybe<string>,
  rawLeadingZero?: boolean,
  withYear?: boolean,
  showShortTZ?: boolean
): string | undefined => {
  if (!str) {
    return undefined;
  }
  try {
    const dateObj = new Date(str);
    if (isValid(dateObj)) {
      const rawStr = dateObj.toLocaleString(CURRENT_LOCALE, {
        timeZone: tz,
        hour12: false,
        ...(showShortTZ && {
          timeZoneName: 'short',
        }),
      } as fixMe);
      const dateStr = getDateFromDatetime(rawStr, rawLeadingZero, withYear);
      if (!showShortTZ) {
        return dateStr;
      }
      const timezoneStr = last((rawStr || '').split(' '));
      return compact([dateStr, timezoneStr]).join(' ');
    }
  } catch {
    // err
  }
  return undefined;
};

// eslint-disable-next-line no-console
const dateSatisfyReportThrottled = throttle((err) => console.error(err), 5000);

export const getTimeFromDatetimeString = (str: string): string => {
  if (!str) {
    return '';
  }
  const matches = str.match(/(\d\d:\d\d)/) || [];
  if (matches[1]) {
    const a = matches[1];
    return a === '24:00' ? '00:00' : a;
  } else {
    dateSatisfyReportThrottled(`${str} does not satisfy a valid Date`);
  }
  return str;
};

/** Convert a utc datetime string to a LOCAL time like 8:00:00 PM CST */
export const utcDatetimeToLocalTime = (
  /** The utcDatetime string like 2019-12-18T21:43:10.587Z */
  str: string | Date,
  /** The timezone like America/Chicago */
  tz: string
): string | undefined => {
  try {
    const dateObj = new Date(str);
    if (isValid(dateObj)) {
      const rawStr = dateObj
        .toLocaleString(CURRENT_LOCALE, {
          timeZone: getTimezone(tz),
          hour12: false,
          timeStyle: 'long',
        } as fixMe)
        // As of writing, chrome will take this date:
        // "1970-01-01T00:02:00.000Z"
        // And produce this result: 24:02:00
        // So we string replace here.
        .replace(/24(:\d\d:\d\d.*)/, '00$1');
      return getTimeFromDatetimeString(rawStr);
    }
  } catch (error) {
    if (MODE === 'development') {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }
  return undefined;
};

export const changeDateTimezoneToUtc = (
  str: string | null | undefined
): Date | null => {
  if (!str) {
    return null;
  }
  const localDate = new Date(str);
  return new Date(
    Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds(),
      localDate.getMilliseconds()
    )
  );
};

export interface GetDatetimeShortOptions {
  showYear?: boolean;
  showShortTZ?: boolean;
}
/** @deprecated Use `getDatetimeValue` instead. Get a string like "12/19/19 11:25". Default options of value and timezone for getDatetimeValue are the same. */
export const DEPRECATEDGetDatetimeShort = (
  rawVal: Maybe<string | Date>,
  tz?: string,
  options: GetDatetimeShortOptions = {
    showYear: true,
    showShortTZ: false,
  }
): string => {
  let d = rawVal;
  if (!d) {
    return '';
  }
  if (typeof d === 'string') {
    d = new Date(d);
  }
  const { showYear = true, showShortTZ = false } = options;
  return (
    d
      .toLocaleString('en-US', {
        hour12: false,
        ...(showYear && {
          year: '2-digit',
        }),
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: tz ? getTimezone(tz) : currentTZ,
        ...(showShortTZ && {
          timeZoneName: 'short',
        }),
      } as anyOk)
      .replace(/,/g, '')
      // chrome comes up with a time of 24:00 as of this comment writing, when the value should actually be 00:00
      // to try it:
      // new Date('2020-02-14T00:00:00').toLocaleString('en-US', {hour12: false})
      // firefox doesn't do this
      .replace(/\s24:(\d\d)/, ' 00:$1')
      .trim()
  );
};

export const getLocalDatetimeShort = (
  d: Date,
  options: { showYear: boolean } = { showYear: true }
): string => {
  return DEPRECATEDGetDatetimeShort(d, undefined, {
    showYear: options.showYear,
  });
};

/** Take a date and convert it to local string, given a timezone */
const toBaseLocaleString = (d: Date, tz: string): string => {
  return (
    d
      .toLocaleString('en-US', {
        hour12: true,
        timeZone: getTimezone(tz),
      })
      // convert non-breaking spaces into regular spaces (Chrome introduced this in v110). This helps date-fns parse the date correctly.
      .replace(/[\u00A0\u1680\u180E\u2000-\u200A\u202F\u205F\u3000]/g, ' ')
  );
};

/** Take date and convert it to a local Date object, given a timezone */
export const dateToTimezoneDate = (str: string | Date, tz: string): Date => {
  const ogDate = new Date(str);
  return parse(toBaseLocaleString(ogDate, tz), 'P, pp', ogDate);
};

/** Test if two dates are on the same calendar day, taking timezone into account. 2:00am in NY is the same day as 1:00am in Chicago. 2:00am in NY is not the same day as 8:00pm in Los Angeles */
export const isSameDay = (
  left: { value: Date; timezone: string },
  right: { value: Date; timezone: string }
): boolean => {
  const a = parseAbsolute(left.value.toISOString(), left.timezone);
  const b = parseAbsolute(right.value.toISOString(), right.timezone);
  return a.day === b.day;
};

/** Get the difference in milliseconds between a utc date object, and a given IANA timezone
 *  This is analagous to getting the "offset" of a timezone, but should be more bulletproof as it will respect DST more accurately.
 */
export const getTimezoneDiff = (utcDate: Date, tz: string): number =>
  differenceInMilliseconds(utcDate, dateToTimezoneDate(utcDate, tz));

/** Mutates to true UTC time from user's local time, taking tz into account */
export const userLocalDateToUTC = (str: string | Date, tz: string): Date => {
  if (MODE !== 'production' && typeof str === 'string' && !str.endsWith('Z')) {
    throw new Error(
      'The ISO Date string passed to this function does not include the Z string at the end. This produces unexpected cross-browser results.'
    );
  }
  const ogDate = new Date(str);
  const localTime = dateToTimezoneDate(str, tz);
  const diffInMillis = ogDate.valueOf() - localTime.valueOf();
  return new Date(ogDate.valueOf() + diffInMillis);
};

/** Mutates to local time from UTC time, taking tz into account */
export const utcDateToLocal = (str: string | Date, tz: string): Date => {
  return dateToTimezoneDate(str, tz);
};

export const getTimeFromLocaleDatetime = (
  dateObj: Date,
  tz: string
): string => {
  return getTimeFromDatetimeString(DEPRECATEDGetDatetimeShort(dateObj, tz));
};

interface FormatRangeArgs {
  start: Maybe<string | Date>;
  end: Maybe<string | Date>;
  tz: string;
  showShortTZ?: boolean;
  includeTimes?: boolean;
  includeDayOfWeek?: boolean;
  withYear?: boolean;
}

export const formatDateRange = ({
  start,
  end,
  tz,
  includeTimes,
  includeDayOfWeek,
  withYear,
}: FormatRangeArgs): string => {
  let startStr = '';
  if (start) {
    startStr = DEPRECATEDgetDateFromLocaleDatetime(
      new Date(start),
      tz,
      withYear
    );
  }
  let startTime = '';
  if (includeTimes && start) {
    startTime = getTimeFromLocaleDatetime(new Date(start), tz);
  }
  let endStr = startStr;
  if (end) {
    endStr = DEPRECATEDgetDateFromLocaleDatetime(new Date(end), tz, withYear);
  }
  let endTime = '';
  if (includeTimes && end) {
    endTime = getTimeFromLocaleDatetime(new Date(end), tz);
  }
  let dayOfWeekStart = '';
  if (includeDayOfWeek && start) {
    dayOfWeekStart = new Date(start).toLocaleDateString(undefined, {
      weekday: 'short',
    });
  }
  let dayOfWeekEnd = '';
  if (includeDayOfWeek && end) {
    dayOfWeekEnd = new Date(end).toLocaleDateString(undefined, {
      weekday: 'short',
    });
  }
  if (includeTimes) {
    const startPrefix = compact([dayOfWeekStart, startStr, startTime]).join(
      ' '
    );
    const endPrefix = compact([dayOfWeekEnd, endStr, endTime]).join(' ');
    if (startStr === endStr) {
      return `${startPrefix} - ${endTime}`;
    } else {
      return `${startPrefix} - ${endPrefix}`;
    }
  }
  return compact(uniq([startStr, endStr])).join(' - ');
};

const isoDateRegex = /\d\d\d\d-\d\d-\d\dT\d\d:\d\d/;

// eslint-disable-next-line no-console
const consoleErrorDebounced = debounce(console.error, 3000, { leading: true });

export const checkForValidCoercion = (dateStr: string | Date): Date => {
  const startDate = new Date(dateStr);
  if (MODE !== 'production') {
    if (!isValid || (isString(dateStr) && !isoDateRegex.test(dateStr))) {
      consoleErrorDebounced(
        new Error(
          `Value passed to formatTimeRange start of ${dateStr} cannot be coerced to a valid Date object.`
        )
      );
    }
  }
  return startDate;
};

export const formatTimeRange = ({
  start,
  end,
  tz,
  showShortTZ,
}: FormatRangeArgs): string => {
  let startStr = '',
    timezoneStr = '';
  if (start) {
    const startDate = checkForValidCoercion(start);
    startStr = getTimeFromLocaleDatetime(startDate, tz);
  }
  let endStr = startStr;
  if (end) {
    const endDate = checkForValidCoercion(end);
    endStr = getTimeFromLocaleDatetime(endDate, tz);
  }
  if (showShortTZ) {
    const dateTime = DEPRECATEDGetDatetimeShort(start, tz, {
      showShortTZ: true,
    }).split(' ');
    if (dateTime.length > 1) {
      timezoneStr = ' ' + dateTime[2];
    }
  }
  return `${compact(uniq([startStr, endStr])).join(' - ')}${timezoneStr}`;
};

export const TimeStringToMsOffsetUTC = (
  timeString?: string
): number | undefined => {
  if (!timeString) {
    return undefined;
  }
  // Converts string in HH:mm or h:mm 24-hour format to number of Milliseconds offset from UNIX epoch.
  // Returns undefined for empty or invalid timeString.
  if (!timeString.length || !timeString.match(/[0-2]?\d:\d\d/)) {
    return undefined;
  }
  const theDate = new Date(0);
  timeString = timeString.padStart(5, '0'); //Pad leading 0 if it's missing.
  theDate.setUTCHours(parseInt(timeString.substring(0, 2)));
  theDate.setUTCMinutes(parseInt(timeString.substring(3)));
  return theDate.getTime();
};

export const MsOffsetUTCToTimeString = (
  timeOffsetMs: number | string
): string => {
  // Takes a number of Milliseconds offset from UNIX epoch and converts to 24-hour time.
  // Can be used with Formik which passes value in as string or with functions passing value in as a number.
  let timeOffsetAsNumber;
  if (typeof timeOffsetMs === 'number') {
    timeOffsetAsNumber = timeOffsetMs;
  } else if (typeof timeOffsetMs === 'string' && timeOffsetMs.length) {
    timeOffsetAsNumber = parseInt(timeOffsetMs);
  }
  if (!isUndefined(timeOffsetAsNumber)) {
    const theDate = new Date();
    theDate.setTime(timeOffsetAsNumber);
    return getTimeFromDatetimeString(theDate.toUTCString());
  } else {
    return '';
  }
};

/** Takes ms and provides a Date object back (1970 epoch) */
export const MsOffsetToDate = (
  timeOffsetMs: number | string
): Date | undefined => {
  let timeOffsetAsNumber;
  if (typeof timeOffsetMs === 'number') {
    timeOffsetAsNumber = timeOffsetMs;
  } else if (typeof timeOffsetMs === 'string' && timeOffsetMs.length) {
    timeOffsetAsNumber = parseInt(timeOffsetMs);
  }
  if (!isUndefined(timeOffsetAsNumber)) {
    const theDate = new Date();
    theDate.setTime(timeOffsetAsNumber);
    return theDate;
  }
  return undefined;
};

export const getDateMillis = (d: Date): number => {
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const seconds = d.getSeconds();
  return hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
};

export const dateToMsOffset = (
  originalDate?: Date | string | null,
  tz = 'Etc/UTC'
): number | undefined => {
  if (originalDate === undefined || originalDate === null) {
    return undefined;
  }
  const d = shiftDateByTimezone(new Date(originalDate), tz, currentTZ);
  return getDateMillis(d);
};

export const format24HrTimeOffsetRange = (
  startMSOffset: number | null,
  endMSOfset: number | null
): string => {
  const vals = [startMSOffset, endMSOfset].filter(isNumber);
  return uniq(vals.map(MsOffsetUTCToTimeString)).join(' - ');
};

export const getTimezoneAbbreviation = memoize((str: string): string => {
  return timezones.find((obj) => obj.utc.find((tz) => tz == str))?.abbr || '';
});

const formatsWithYear: Array<[string, RegExp]> = [
  ['MM/dd/yyyy', /^\d\d\/\d\d\/\d\d\d\d$/],
  ['yyyy/MM/dd', /^\d\d\d\d\/\d\d\/\d\d$/],
  ['MM/dd/yy', /^\d\d\/\d\d\/\d\d$/],
  ['MM/dd/yy', /^\d\/\d\d\/\d\d$/],
  ['MM/dd/yy', /^\d\d\/\d\/\d\d$/],
  ['MM/dd/yy', /^\d\/\d\/\d\d$/],
  ['MM/dd/yy', /^\d\d\d\d\d\d\d\d$/],
  ['MM/dd/yyyy', /^\d\/\d\d\/\d\d\d\d$/],
  ['MM/dd/yyyy', /^\d\/\d\/\d\d\d\d$/],
  ['MM/d/yyyy', /^\d\d\/\d\/\d\d\d\d$/],
];

export const strHasYear = (str: string): boolean => {
  for (const [, matcher] of formatsWithYear) {
    if (str.match(matcher)) {
      return true;
    }
  }
  return false;
};

const supportedFormats: Array<[string, RegExp]> = formatsWithYear.concat([
  ['MM/dd', /^\d\d\/\d\d$/],
  ['MM/d', /^\d\d\/\d$/],
  ['M/dd', /^\d\/\d\d$/],
  ['M/d', /^\d\/\d$/],
  ['MMdd', /^\d\d\d\d$/],
  ['MMd', /^\d\d\d$/],
  ['MM', /^\d\d$/],
]);

export const parseStringDate = (rawStr: string): Date | undefined => {
  try {
    const str = (rawStr || '').replace(/[-.]/g, '/').trim();
    for (const [template, matcher] of supportedFormats) {
      if (str.match(matcher)) {
        return parse(str, template, new Date());
      }
    }
  } catch {
    // noop
  }
  return undefined;
};

export const createDateWithTimezone = (
  str: string,
  tz: string
): Date | undefined => {
  const parsed = parseStringDate(str);
  if (!parsed) {
    return undefined;
  }
  const diff = getTimezoneDiff(parsed, tz);
  return new Date(parsed.valueOf() + diff);
};

/** Mutates the date by shifting it N number of hours away from the user's timezone. Helpful for things like the Datepicker which always assumes local time. */
export const shiftDateByTimezone = (
  d: Date,
  tzFrom: string,
  tzTo: string
): Date => {
  const userTZ = tzFrom || currentTZ;
  const fromDate = dateToTimezoneDate(d, userTZ);
  const toDate = dateToTimezoneDate(d, tzTo);
  const diff = (fromDate?.valueOf() || 0) - (toDate?.valueOf() || 0);
  return new Date(d.valueOf() + diff);
};

enum DayOfWeek {
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
}

const allDayOfWeekStrings = Object.values(DayOfWeek).filter(isString);

/** Get day of week for a given Date and timezone. Lowercase - ie "wednesday" */
export const getDayOfWeek = (
  rawDate: Maybe<Date | string>,
  tz: string
): DayOfWeek | undefined => {
  if (!rawDate) {
    return undefined;
  }
  return new Date(rawDate)
    .toLocaleDateString('en-US', {
      weekday: 'long',
      timeZone: getTimezone(tz),
    })
    .toLowerCase() as unknown as DayOfWeek;
};

export const getStartOfDay = (
  rawDate: Maybe<Date | string>,
  tz: string
): Date | undefined => {
  if (!rawDate) {
    return undefined;
  }
  const shiftedDate = shiftDateByTimezone(new Date(rawDate), tz, currentTZ);
  return shiftDateByTimezone(startOfDay(shiftedDate), currentTZ, tz);
};

/** Get a ISO-compliant date like 2022-04-12 that takes TZ into account. As of writing, Datepicker component defaults to user's current TZ. To get a date part for use in some APIs, we shift the date by UTC offset and use ISOString. This util is good for when locale is NOT involved. */
export const getISODatePart = (
  rawDate: Maybe<Date | string>,
  /** Defaults to currentTZ, like a date generated by Datepicker */
  tz?: string
): string | undefined => {
  if (!rawDate) {
    return undefined;
  }
  const newRawDate = new Date(rawDate);
  if (!isValid(newRawDate)) {
    return undefined;
  }
  return shiftDateByTimezone(newRawDate, tz ?? currentTZ, 'Etc/UTC')
    .toISOString()
    .slice(0, 10);
};

export const getEndOfDay = (
  rawDate: Maybe<Date | string>,
  tz: string
): Date | undefined => {
  if (!rawDate) {
    return undefined;
  }
  const shiftedDate = shiftDateByTimezone(new Date(rawDate), tz, currentTZ);
  return shiftDateByTimezone(endOfDay(shiftedDate), currentTZ, tz);
};

interface BaseProps {
  date: Date | string;
  timezone?: string;
}

interface WithTime {
  time: Date | number | string;
}

interface WithTimeOffset {
  timeOffsetMs: number;
}

type CombineDateAndTimeArgs = BaseProps &
  MergeExclusive<WithTime, WithTimeOffset>;

/** Combine two dates, one that signifies the day to be taken, the other the time to be taken. Used in some of our forms where we have two independent inputs. The function assumes both values originate from the same timezone. */
export const combineDateAndTime = ({
  date,
  time,
  timeOffsetMs,
  timezone: tzProp,
}: CombineDateAndTimeArgs): Date => {
  // Explicitly handle empty string use case
  const timezone = tzProp || currentTZ;
  const parsedTime = parseAbsolute(
    time ? new Date(time).toISOString() : new Date().toISOString(),
    timezone
  );
  const parsedDate = parseAbsolute(
    date ? new Date(date).toISOString() : new Date().toISOString(),
    timezone
  );
  if (time) {
    return parsedDate
      .set({
        hour: parsedTime.hour,
        minute: parsedTime.minute,
        second: parsedTime.second,
        millisecond: parsedTime.millisecond,
      })
      .toDate();
  } else if (timeOffsetMs) {
    return parsedDate.set(millisToIntlSetObj(timeOffsetMs)).toDate();
  }
  return parsedDate.toDate();
};

export const getTodayWithTimezone = (): Date | undefined => {
  return createDateWithTimezone(format(new Date(), 'yyyy-MM-dd'), currentTZ);
};

export const sortByDate = (a: string, b: string): number => {
  const a1 = new Date(a).valueOf();
  const b1 = new Date(b).valueOf();
  if (a1 < b1) {
    return 1;
  } else if (a1 > b1) {
    return -1;
  } else {
    return 0;
  }
};

/** Takes a zone like America/Chicago and returns a string like CST or CDT */
export const getTimezoneAbbr = (
  ianaTimezoneString: Maybe<string>,
  date?: Maybe<Date>
): string | undefined => {
  if (!ianaTimezoneString) {
    return undefined;
  }
  const d = createDateWithTimezone(
    format(date || new Date(), 'yyyy-MM-dd'),
    ianaTimezoneString
  );
  if (d) {
    const parsed = DEPRECATEDGetDatetimeShort(d, ianaTimezoneString, {
      showYear: false,
      showShortTZ: true,
    });
    const found = last(parsed.split(' '));
    // IANA timezone abbr list seems to require at least two characters
    // https://en.wikipedia.org/wiki/List_of_time_zone_abbreviations
    if (found?.match(/\w\w/)) {
      return found;
    }
  }
  return undefined;
};

export const getFormattedStartEndAvailable = (
  stop: Maybe<{
    availableStart?: Maybe<DatetimeWithTimezoneInput>;
    availableEnd?: Maybe<DatetimeWithTimezoneInput>;
  }>,
  tz: Maybe<string>
): string => {
  if (!stop) {
    return '';
  }
  return formatDateRange({
    start: stop.availableStart?.value,
    end: stop.availableEnd?.value,
    tz: stop.availableStart?.timezone || tz || currentTZ,
  });
};

interface BetweenDaysKwargs {
  start: Maybe<Date | string>;
  startTimezone: Maybe<string>;
  end: Maybe<Date | string>;
  endTimezone: Maybe<string>;
  /** Include start and end in array */
  inclusive?: boolean;
}

const getDifferenceInCalendarDaysWithinRange = (
  kwargs: BetweenDaysKwargs
): number | undefined => {
  const { start, startTimezone, end, endTimezone } = kwargs;
  if (!start || !startTimezone || !end || !endTimezone) {
    return undefined;
  }
  return differenceInCalendarDays(new Date(end), new Date(start));
};

export const getDatesWithinRange = (
  kwargs: BetweenDaysKwargs
): Array<Date> | undefined => {
  const { start, startTimezone, end, endTimezone, inclusive } = kwargs;
  if (!start || !startTimezone || !end || !endTimezone) {
    return undefined;
  }
  const diff = differenceInCalendarDays(new Date(end), new Date(start));
  const middle = range(diff).map((i) => {
    return addDaysFns(new Date(start), i + 1);
  });
  return compact([inclusive && new Date(start), ...middle]);
};

const arrIsFull = <T extends unknown>(arr: Maybe<T>[]): arr is T[] => {
  return !arr.find(isNil);
};

/** Returns named days like "sunday", "monday" that are between two dates. Named days will not repeat, ie if the dates are further than 1 week apart, only 7 named days will be returned. */
export const getNamedDaysBetweenDates = (
  kwargs: BetweenDaysKwargs
): undefined | DayOfWeek[] => {
  const { start, startTimezone, end, endTimezone } = kwargs;
  if (!start || !startTimezone || !end || !endTimezone) {
    return undefined;
  }
  const num = getDifferenceInCalendarDaysWithinRange(kwargs);
  if (isNumber(num) && num > 30) {
    return allDayOfWeekStrings as anyOk;
  }
  const dates = getDatesWithinRange(kwargs);
  const arr = dates?.map((d) => {
    if (!kwargs.startTimezone) {
      return undefined;
    }
    return getDayOfWeek(d, kwargs.startTimezone);
  });
  if (!arr || !arrIsFull(arr)) {
    return undefined;
  }
  return uniq(arr);
};

interface TimeSpanValue {
  amount: number;
  label?: string;
  display: string;
}

interface TimeSpanObj {
  days: TimeSpanValue;
  hours: TimeSpanValue;
  minutes: TimeSpanValue;
}

interface minutesToTimespanObjProps {
  minutesInput: number;
  abbreviate?: boolean;
}

const minutesToTimespanObj = (args: minutesToTimespanObjProps): TimeSpanObj => {
  const { minutesInput, abbreviate = false } = args;
  const hours: TimeSpanValue = {
    amount: Math.floor(minutesInput / 60),
    display: '',
    label: '',
  };
  const minutes: TimeSpanValue = {
    amount: Math.floor(minutesInput - hours.amount * 60),
    display: '',
    label: '',
  };
  const days: TimeSpanValue = {
    amount: Math.floor(hours.amount / 24),
    display: '',
    label: '',
  };
  hours.amount = Math.floor(hours.amount - days.amount * 24);

  if (days.amount > 0) {
    if (days.amount === 1) {
      days.label = `day`;
    } else {
      days.label = `days`;
    }
    days.display = `${days.amount} ${days.label}`;
  }

  if (
    (days.amount == 0 && hours.amount == 0) ||
    (hours.amount == 0 && minutes.amount == 0) ||
    (days.amount != 0 && hours.amount == 0 && minutes.amount != 0)
  ) {
    hours.label = '';
  } else {
    if (hours.amount === 1) {
      hours.label = abbreviate ? 'hr' : 'hour';
    } else {
      hours.label = abbreviate ? 'hrs' : 'hours';
    }
    hours.display = `${hours.amount} ${hours.label}`;
  }

  if (minutes.amount > 0) {
    if (minutes.amount === 1) {
      minutes.label = abbreviate ? 'min' : 'minute';
    } else {
      minutes.label = abbreviate ? 'min' : 'minutes';
    }
    minutes.display = `${minutes.amount} ${minutes.label}`;
  }

  return { days, hours, minutes };
};

/* example: 1442 minutes => 1 day, 0 hour, 2 minutes */
export const minutesToTimespan = (minutesInput: Maybe<number>): string => {
  if (!minutesInput || minutesInput < 0) {
    return '';
  }

  const { days, hours, minutes } = minutesToTimespanObj({ minutesInput });

  const dSeparator =
    days.amount == 0 || (hours.amount == 0 && minutes.amount == 0) ? '' : ', ';

  const hSeparator =
    (days.amount == 0 && hours.amount == 0) ||
    (hours.amount == 0 && minutes.amount == 0) ||
    (days.amount != 0 && hours.amount == 0 && minutes.amount != 0) ||
    minutes.amount == 0
      ? ''
      : ', ';

  return `${days.display}${dSeparator}${hours.display}${hSeparator}${minutes.display}`;
};

/** Get the distance between two dates, with 2 degrees of resolution. ie "1 hr, 30 min" or "2 days, 5 hrs". Potentially useful for display in a table cell. If you want a humanized, localized display like "12 hours ago", use `getTimeAgoValue`. */
export const getTimeDistance = (args: {
  base?: Date | string;
  compare?: Date | string;
}): string | null => {
  const { base: oldDateRaw, compare: newDateRaw = new Date() } = args;
  if (!oldDateRaw) {
    return null;
  }
  const oldDate = new Date(oldDateRaw);
  const newDate = new Date(newDateRaw);
  const diff = newDate.getTime() - oldDate.getTime();
  const diffInMinutes = Math.floor(diff / (1000 * 60));
  const { days, hours, minutes } = minutesToTimespanObj({
    minutesInput: diffInMinutes,
    abbreviate: true,
  });
  let result = '';
  if (hours.amount < 1) {
    result = `${minutes.display}`;
  } else if (hours.amount >= 1 && days.amount < 1) {
    result = `${hours.display} ${minutes.display}`;
  } else if (days.amount >= 1) {
    result = `${days.display} ${hours.display}`;
  }
  return result.trim();
};

/**
 * The function `getTimeZone` accepts a `Date` object, an IANA time zone identifier (e.g., "America/New_York"),
 * and a format type ('short' or 'long'). It returns the time zone name corresponding to the provided date
 * and time zone in the specified format. If the date is not provided, the function returns `undefined`.
 *
 * The format type determines the verbosity of the time zone name returned:
 * - 'short': Abbreviated timezone name (e.g., "EST", "PDT").
 * - 'long': Full timezone name (e.g., "Eastern Standard Time", "Pacific Daylight Time").
 *
 * @param {Date | undefined} date - The date for which the time zone name is needed. If undefined, the function returns undefined.
 * @param {IANATimezones} timeZone - An IANA time zone identifier (e.g., "America/New_York").
 * @param {'short' | 'long'} type - The format type for the time zone name ('short' or 'long').
 * @returns {string | undefined} The time zone name in the specified format, or undefined if the date is not provided.
 *
 * @example
 * // returns "Central Daylight Time"
 * getTimeZone(new Date(), 'America/Chicago', 'long');
 *
 * @example
 * // returns "CDT"
 * getTimeZone(new Date(), 'America/Chicago', 'short');
 */
export function getTimeZone(
  date: Date | undefined,
  timeZone: IANATimezones,
  type: 'short' | 'long'
): string | undefined {
  // Check if the date is provided, return undefined if not
  if (!date) {
    return undefined;
  }
  // Create a new Intl.DateTimeFormat object configured for the specified time zone and format type
  const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
    timeZone: timeZone,
    timeZoneName: type,
  };
  const formatter = new Intl.DateTimeFormat('en-US', dateTimeFormatOptions);

  // Format the provided date using the formatter
  const formattedDateTime = formatter.format(date);

  // Extract the time zone part from the formatted date and time string
  const timeZonePart = formattedDateTime.split(',')[1]?.trim();

  // Return the extracted time zone part or undefined if it's not available
  // Example: Long: "Central Daylight Time" OR Short: "CDT"
  return timeZonePart || undefined;
}

export function getTimeZoneLabel(
  longTimeZone: string | undefined,
  shortTimeZone: string | undefined
): string | undefined {
  if (longTimeZone && shortTimeZone) {
    return `${longTimeZone} (${shortTimeZone})`;
  } else if (longTimeZone) {
    return longTimeZone;
  }
  return undefined;
}

export const isValidDate = (val: anyOk): boolean => {
  return isValid(val);
};

// ts-unused-exports:disable-next-line
export const addDays = addDaysFns;
// ts-unused-exports:disable-next-line
export const subDays = subDaysFns;

/**
 * Checks if the selected date is within the maximum allowable date.
 *
 * @param selectedDate - The date to check.
 * @param minDate - The minimum allowable date.
 * @param maxDate - The maximum allowable date.
 * @returns `true` if the selected date is before or on the same day as the max date, `false` otherwise.
 */
export const isDateWithinDefaultDatePickerRange = (
  selectedDate: Date | null,
  minDate: Date,
  maxDate: Date
): boolean => {
  if (selectedDate === null || selectedDate === undefined) {
    return true;
  }

  if (isBefore(selectedDate, minDate)) {
    return false;
  }

  if (isAfter(selectedDate, maxDate)) {
    return false;
  }

  return true;
};
