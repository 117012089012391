import { FetchPolicy } from '@apollo/client';
import { IS_HEADER_ALLOWED } from '@app/Sidebar/constants';
import { LinkRoutePath, MasterfindRoute } from '@app/routes';
import { getAddressDisplayString } from '@components/AddressDisplay';
import { AutoComplete, Shell } from '@components/AutoComplete';
import { getDatetimeValue } from '@components/DatetimeValue';
import { Grid } from '@components/Grid';
import { Icon, IconProp } from '@components/Icon';
import { DEFAULT_LOADING_BAR_HEIGHT, LoadingBar } from '@components/LoadingBar';
import { Logo } from '@components/Logo';
import { NewWindowLinkProps, openNewWindow } from '@components/NewWindowLink';
import { usePermissionsContext } from '@components/PermissionsContext';
import { useShortcut } from '@components/Shortcut';
import { PRIVATE_themeObj } from '@components/Theme';
import { Tooltip } from '@components/Tooltip';
import { Theme } from '@emotion/react';
import { useFlagMe115898ViewDriverRecord } from '@generated/flags/ME-115898-view-driver-record';
import { useFlagMe115985ViewTrailerRecord } from '@generated/flags/ME-115985-view-trailer-record';
import { useFlagMe115990ViewPowerRecord } from '@generated/flags/ME-115990-view-power-record';
import { useFlagMe121162FeatEnableMasterfindExpandSearchView } from '@generated/flags/ME-121162-feat-enable-masterfind-expand-search-view';
import { useFlagMe32857AppMasterfindRegex } from '@generated/flags/ME-32857-app-masterfind-regex';
import { useFlagMe35600FeatMasterfindDebounceMs } from '@generated/flags/ME-35600-feat-masterfind-debounce-ms';
import { SeerLoadMasterfindFragment } from '@generated/fragments/seerLoadMasterfind';
import {
  CarriersForMasterfindV2Document,
  CarriersForMasterfindV2Query,
  CarriersForMasterfindV2QueryVariables,
} from '@generated/queries/carriersForMasterfindV2';
import {
  LoadsForMasterfindV3Document,
  LoadsForMasterfindV3Query,
  LoadsForMasterfindV3QueryVariables,
  MasterfindLoadV3Fragment,
} from '@generated/queries/loadsForMasterfindV3';
import { SeerGenericOrder, SeerGenericStringOperator } from '@generated/types';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { useLazyQueryWithDataPromise } from '@hooks/useLazyQueryWithDataPromise';
import { useTheme } from '@hooks/useTheme';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { FS_UNMASK, FullstoryEventTypes, fsEvent } from '@utils/fullstory';
import { history } from '@utils/history';
import { pipeSeparator } from '@utils/htmlEntities';
import { filterByPromiseFulfilled } from '@utils/promise';
import { win } from '@utils/win';
import { MODAL_BACKGROUND } from '@utils/zIndex';
import { CarrierPickerItemDisplay } from '@views/Common/CarrierPicker';
import {
  CustomerPickerItemDisplay,
  useCustomerSearch,
} from '@views/Common/CustomerPicker';
import {
  FacilityPickerItemDisplay,
  useFacilityCombinedSearch,
} from '@views/Common/FacilityPicker';
import {
  getResolvedPermissionValues,
  useHasPermission,
} from '@views/Common/Permissions';
import { RenderPowerSearchItem, useSearchPowerPromise } from './Vectors/power';
import {
  RenderTrailerSearchItem,
  useSearchTrailerPromise,
} from './Vectors/trailer';
import { BASE_MAX_ITEM_LIMIT } from './constants';

import { useTenantConfig } from '@app/TenantConfig';
import { useFlagMe248326MasterfindSearchForAssetObjects } from '@generated/flags/ME-248326-masterfind-search-for-asset-objects';
import { useFlagMe276723EnableMasterfindLoadBottomTabsDrawerCollapsed } from '@generated/flags/ME-276723-enable-masterfind-load-bottom-tabs-drawer-collapsed';
import { MinionConfigTypes } from '@minion/types';
import { PermissionsScopeNames } from '@views/Common/Permissions/constants';
import { useActor, useMachine } from '@xstate/react';
import Downshift from 'downshift';
import fuzzy from 'fuzzy';
import {
  compact,
  filter,
  flattenDeep,
  isArray,
  map,
  noop,
  omitBy,
  reject,
  sortBy,
  toPairs,
  uniqBy,
  uniqueId,
} from 'lodash-es';
import Cancelable from 'p-cancelable';
import {
  FC,
  Fragment,
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePrevious } from 'react-use';
import { SetOptional } from 'type-fest';
import { Machine } from 'xstate';
import { WALKME_TOGGLE_BUTTON_ID } from '../layout/Header';
import { MasterFindGlobalStateContext } from './MasterFindGlobalStateProvider';
import {
  RenderDriverSearchItem,
  useSearchDriverPromise,
} from './Vectors/driver';
const stateTypes = Downshift.stateChangeTypes;

interface MasterfindContextType {
  actions: ActionDef[];
  addAction: (action: ActionDef) => void;
  removeAction: (action: Partial<ActionDef>) => void;
}

const defaultCtx: MasterfindContextType = {
  actions: [],
  addAction: noop,
  removeAction: noop,
};

const MasterfindContext = createContext<MasterfindContextType>(defaultCtx);

export const MasterfindProvider: FC = ({ children }) => {
  const appWideActions = useGetActions();
  const [actions, setActions] = useState<ActionDef[]>(appWideActions);
  const addAction = useCallback((newAction: ActionDef): void => {
    setActions((prevActions): ActionDef[] => {
      return uniqBy([newAction].concat(prevActions), (obj) => obj.id);
    });
  }, []);

  const removeAction = useCallback(
    (actionToRemove: Partial<ActionDef>): void => {
      setActions((prevActions): ActionDef[] => {
        return reject(prevActions, (obj) => obj.id === actionToRemove.id);
      });
    },
    []
  );

  const value = useMemo(
    () => ({ actions, addAction, removeAction }),
    [actions, addAction, removeAction]
  );

  return (
    <MasterfindContext.Provider value={value}>
      {children}
    </MasterfindContext.Provider>
  );
};

/** Add an action to the masterfind actions list. The action is added  host component mount, and automatically removed on host component unmount. You can conditionally enable the action by using the hidden or visible option and passing any boolean. You can call this hook multiple times to add multiple actions. The actions is prepended to the list. So hook call #2 will be first in the list, #1 will be second. */
export const useMasterfindAction = (
  generateActionCallback: () => ActionDef,
  deps: anyOk[]
): void => {
  const { addAction, removeAction } = useContext(MasterfindContext);
  const action = useMemo(() => {
    return { ...generateActionCallback(), actionType: 'Custom Action' };
    // NOTE: do not pass generateActionCallback into the deps below
    // This var is a function provided by consumers and is not guaranteed to be memoized/stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  useEffect(() => {
    addAction(action);
    return (): void => {
      removeAction(action);
    };
  }, [action, addAction, removeAction]);
};

interface MFStateSchema {
  states: {
    inactive: Record<string, unknown>;
    active: Record<string, unknown>;
    loading: Record<string, unknown>;
  };
}

interface FsPayloadEventDef {
  action: string;
  charactersSearched?: number | null;
  resultTypes?: string[] | null;
  numResults?: number | null;
}
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type MFEvent =
  | { type: 'TOGGLE' }
  | { type: 'CLOSE' }
  | { type: 'INPUT_CHANGE' }
  | { type: 'LOAD_COMPLETE' }
  | { type: 'LOAD_START' }
  | { type: 'RESET' };

interface MFContext {
  elapsed: number;
}

export const mfMachine = Machine<MFContext, MFStateSchema, MFEvent>({
  id: 'masterfind',
  initial: win.location.search.includes('masterfind=true')
    ? 'active'
    : 'inactive',
  states: {
    inactive: {
      on: { TOGGLE: 'active.default' },
    },
    active: {
      initial: 'default' as fixMe,
      on: {
        TOGGLE: 'inactive',
        CLOSE: 'inactive',
        RESET: 'active.default',
        INPUT_CHANGE: 'active.hasInput',
      },
      states: {
        default: {},
        hasInput: {
          on: {
            LOAD_START: '#loading.stage0',
          },
        },
        loaded: {},
        error: {},
      },
    },
    loading: {
      id: 'loading',
      states: {
        stage0: {
          after: {
            500: 'stage1',
          },
        },
        stage1: {
          after: {
            5000: 'stage2',
          },
        },
        stage2: {
          after: {
            10000: 'error',
          },
        },
        error: {},
      },
      on: {
        INPUT_CHANGE: 'active.hasInput',
        LOAD_COMPLETE: 'active.loaded',
        CLOSE: 'inactive',
        RESET: 'active.default',
      },
    },
  },
});

const FONT_SIZE = 20;

interface ActionDef {
  name?: string;
  linkProps?: NewWindowLinkProps;
  action?: () => void;
  icon?: IconProp;
  id: string;
  hidden?: boolean;
  /** The string that Masterfind will use fuzzy searching on. Typically the same as the name/label but can be different */
  searchVector: string;
  visible?: boolean;
  disabled?: Shell<unknown>['disabled'];
  label?: (kwargs: { item: ActionDef; theme: Theme }) => ReactNode;
  isTitle?: boolean;
  /** Check keycloak permissions to show the action, the user must have all permissions in the array. */
  permissions?: PermissionsScopeNames[];
  actionType?: string;
  objectType?: string;
}

export const getActionId = (id: string): string => id;

const defaultActionsTitle: ActionDef = {
  name: 'Actions',
  disabled: true,
  searchVector: 'actions',
  id: getActionId('app-actions-title'),
};

const getLinkProps = (url: LinkRoutePath): NewWindowLinkProps => {
  return {
    to: url,
    header: true,
    sidebar: true,
  };
};

const useGetActions = (): ActionDef[] => {
  const routeActionMap: Record<
    MasterfindRoute,
    Omit<SetOptional<ActionDef, 'id'>, 'disabled'>
  > = {
    '/': {
      name: 'Home',
      linkProps: getLinkProps('/'),
      icon: 'arrowRightSolid',
      searchVector: 'Home',
      actionType: 'Navigate',
    },
    '/loads/create': {
      name: 'Loads - Add New',
      action: (): void => {
        openNewWindow({
          to: `/loads/create?row=0&section=order`,
        });
      },
      icon: 'plus',
      searchVector: 'Add New - Loads - Add New',
      permissions: ['load:create'],
      actionType: 'Navigate',
    },
    '/loads': {
      name: 'Loads - Search',
      linkProps: getLinkProps('/loads'),
      icon: 'search',
      searchVector: 'Search - Loads - Search',
      permissions: ['load:view'],
      actionType: 'Navigate',
    },
    '/load-repeat': {
      name: 'Loads - Repeat',
      linkProps: getLinkProps('/load-repeat'),
      icon: 'arrowRightSolid',
      searchVector: 'Loads - Repeat - Loads',
      permissions: ['load:repeat'],
      actionType: 'Navigate',
    },
    '/track': {
      name: 'Loads - Track',
      linkProps: getLinkProps('/track'),
      icon: 'arrowRightSolid',
      searchVector: 'Loads - Track - Loads',
      permissions: ['load:track:view'],
      actionType: 'Navigate',
    },
    '/map': {
      name: 'Book - State Map',
      linkProps: getLinkProps('/map'),
      icon: 'arrowRightSolid',
      searchVector: 'Book - State Map - Book',
      permissions: ['book:stateMap:view'],
      actionType: 'Navigate',
    },
    '/available-routes': {
      name: 'Book - Available Routes',
      linkProps: getLinkProps('/available-routes'),
      icon: 'arrowRightSolid',
      searchVector: 'Book - Available Routes - Book',
      permissions: ['book:availableLoads:view'],
      actionType: 'Navigate',
    },
    '/matches': {
      name: 'Book - Matches',
      linkProps: getLinkProps('/matches'),
      icon: 'arrowRightSolid',
      searchVector: 'Book - Matches - Book',
      permissions: ['book:myMatches:view'],
      actionType: 'Navigate',
    },
    '/offers': {
      name: 'Book - Offers',
      linkProps: getLinkProps('/offers'),
      icon: 'arrowRightSolid',
      searchVector: 'Book - Offers - Book',
      permissions: ['offer:view'],
      actionType: 'Navigate',
    },
    '/capacity/search': {
      name: 'Book - Capacity Search',
      linkProps: getLinkProps('/capacity/search'),
      icon: 'arrowRightSolid',
      searchVector:
        'Book - Capacity Search - Book - Capacity - Truck - Gate Reservation - Container',
      actionType: 'Navigate',
    },
    '/carriers/search': {
      name: 'Carrier - Search',
      linkProps: getLinkProps('/carriers/search'),
      icon: 'search',
      searchVector: 'Search - Carrier - Search',
      permissions: ['carrier:view'],
      actionType: 'Navigate',
    },
    '/carriers/add': {
      name: 'Carrier - Add New',
      linkProps: getLinkProps('/carriers/add'),
      icon: 'plus',
      searchVector: 'Add New - Carrier - Add New',
      permissions: ['carrier:create'],
      actionType: 'Navigate',
    },
    '/facilities/add': {
      name: 'Facility - Add New',
      linkProps: getLinkProps('/facilities/add'),
      icon: 'plus',
      searchVector: 'Add New - Facility - Add New',
      permissions: ['facility:edit'],
      actionType: 'Navigate',
    },
    '/facilities/search': {
      name: 'Facility - Search',
      linkProps: getLinkProps('/facilities/search'),
      icon: 'search',
      searchVector: 'Search - Facility - Search',
      permissions: ['facility:view'],
      actionType: 'Navigate',
    },
    '/customers/add': {
      name: 'Customer - Add New',
      linkProps: getLinkProps('/customers/add'),
      icon: 'plus',
      searchVector: 'Add New - Customer - Add New',
      permissions: ['customer:create'],
      actionType: 'Navigate',
    },
    '/customers/search': {
      name: 'Customer - Search',
      linkProps: getLinkProps('/customers/search'),
      icon: 'search',
      searchVector: 'Search - Customer - Search',
      permissions: ['customer:view'],
      actionType: 'Navigate',
    },
    '/employees/add': {
      name: 'Employee - Add New',
      linkProps: getLinkProps('/employees/add'),
      icon: 'plus',
      searchVector: 'Add New - Employee - Add New',
      permissions: ['employee:create'],
      actionType: 'Navigate',
    },
    '/employees/search': {
      name: 'Employee - Search',
      linkProps: getLinkProps('/employees/search'),
      icon: 'search',
      searchVector: 'Search - Employee - Search',
      permissions: ['employee:view'],
      actionType: 'Navigate',
    },
    '/accounting/uninvoiced': {
      name: 'Accounting - Uninvoiced',
      linkProps: getLinkProps('/accounting/uninvoiced'),
      icon: 'arrowRightSolid',
      searchVector: 'Accounting Uninvoiced',
      permissions: ['accounting:customerSettlement:view'],
      actionType: 'Navigate',
    },
    '/accounting/invoices': {
      name: 'Accounting - Invoices',
      linkProps: getLinkProps('/accounting/invoices'),
      icon: 'arrowRightSolid',
      searchVector: 'Accounting Invoices',
      permissions: ['accounting:invoices:view'],
      actionType: 'Navigate',
    },
    '/accounting/unvouchered': {
      name: 'Accounting - Unvouchered',
      linkProps: getLinkProps('/accounting/unvouchered'),
      icon: 'arrowRightSolid',
      searchVector: 'Accounting Unvouchered',
      permissions: ['accounting:vendorSettlement:view'],
      actionType: 'Navigate',
    },
    '/accounting/vouchers': {
      name: 'Accounting - Vouchers',
      linkProps: getLinkProps('/accounting/vouchers'),
      icon: 'arrowRightSolid',
      searchVector: 'Accounting Vouchers',
      permissions: ['accounting:vouchers:view'],
      actionType: 'Navigate',
    },
    '/accounting/vendor-invoice': {
      name: 'Accounting - Vendor Invoice',
      linkProps: getLinkProps('/accounting/vendor-invoice'),
      icon: 'arrowRightSolid',
      searchVector: 'Accounting Vendor Invoice',
      permissions: ['accounting:vendorInvoice:view'],
      actionType: 'Navigate',
    },
    '/accounting/bulk-rerating': {
      name: 'Accounting - Bulk Rerating',
      linkProps: getLinkProps('/accounting/bulk-rerating'),
      icon: 'arrowRightSolid',
      searchVector: 'Accounting Bulk Rerating',
      actionType: 'Navigate',
    },
    '/trailers/add': {
      name: 'Trailer - Add New',
      linkProps: getLinkProps('/trailers/add'),
      icon: 'arrowRightSolid',
      searchVector: 'Add New - Trailer - Add New',
      permissions: ['trailer:create'],
      actionType: 'Navigate',
      hidden: !useFlagMe115985ViewTrailerRecord(),
    },
    '/trailers/search': {
      name: 'Trailer - Search Trailer',
      linkProps: getLinkProps('/trailers/search'),
      icon: 'arrowRightSolid',
      searchVector: 'Search Trailer- Trailer - Search Trailer',
      permissions: ['trailer:view'],
      actionType: 'Navigate',
      hidden: !useFlagMe115985ViewTrailerRecord(),
    },
    '/lanes': {
      name: 'Lane',
      linkProps: getLinkProps('/lanes'),
      icon: 'arrowRightSolid',
      searchVector: 'Lane',
      permissions: ['customer:view'],
      hidden: true,
      actionType: 'Navigate',
    },
    '/projects': {
      name: 'Project',
      linkProps: getLinkProps('/projects'),
      icon: 'arrowRightSolid',
      searchVector: 'Project',
      hidden: true,
      actionType: 'Navigate',
    },
    '/patterns': {
      name: 'Pattern',
      linkProps: getLinkProps('/patterns'),
      icon: 'arrowRightSolid',
      searchVector: 'Pattern',
      permissions: ['customer:view'],
      hidden: true,
      actionType: 'Navigate',
    },
    '/patterns/add': {
      name: 'Pattern - Add New',
      linkProps: getLinkProps('/patterns/add'),
      icon: 'arrowRightSolid',
      searchVector: 'Pattern Add New',
      permissions: ['customer:view'],
      hidden: true,
      actionType: 'Navigate',
    },
    '/powers/add': {
      name: 'Power - Add New',
      linkProps: getLinkProps('/powers/add'),
      icon: 'plus',
      searchVector: 'Power Add New',
      permissions: ['power:view'],
      actionType: 'Navigate',
      hidden: !useFlagMe115990ViewPowerRecord(),
    },
    '/powers/search': {
      name: 'Power - Search',
      linkProps: getLinkProps('/powers/search'),
      icon: 'search',
      searchVector: 'Search - Power - Search',
      permissions: ['power:view'],
      actionType: 'Navigate',
      hidden: !useFlagMe115990ViewPowerRecord(),
    },
    '/drivers/search': {
      name: 'Driver - Search',
      linkProps: getLinkProps('/drivers/search'),
      icon: 'search',
      searchVector: 'Search - Driver - Search',
      permissions: ['driver:view'],
      actionType: 'Navigate',
      hidden: !useFlagMe115898ViewDriverRecord(),
    },
    '/drivers/add': {
      name: 'Driver - Add New',
      linkProps: getLinkProps('/drivers/add'),
      icon: 'plus',
      searchVector: 'Add New - Driver - Add New',
      permissions: ['driver:create'],
      actionType: 'Navigate',
      hidden: !useFlagMe115898ViewDriverRecord(),
    },
  };

  const routeActions: ActionDef[] = toPairs(routeActionMap).map(
    ([key, val]) => {
      return {
        ...val,
        id: val.id || key,
        searchVector: val.searchVector || val.name || val.id || '',
        isTitle: true,
        type: val?.actionType ?? 'Unknown Action Type',
      };
    }
  );

  const extraActions: ActionDef[] = [
    {
      name: 'Help',
      icon: 'question',
      id: getActionId('app-help'),
      action: (): void => {
        const btn = document.querySelector(`#${WALKME_TOGGLE_BUTTON_ID}`);
        if (btn instanceof HTMLElement) {
          btn.click();
        }
      },
      searchVector: 'Help',
      actionType: 'Navigate',
    },
  ];

  const combinedActions = routeActions.concat(extraActions);

  const appWideActions: ActionDef[] = sortBy(combinedActions, (obj) =>
    obj.name === 'Help' ? 'zzzz' : obj.name
  );
  return appWideActions;
};

const FETCH_POLICY: FetchPolicy = 'network-only';

const useCarriersSearchV2 = (): ((text: string) => Promise<ActionDef[]>) => {
  const canSearch = useHasPermission('carrier:view');
  const callQuery = useLazyQueryWithDataPromise<
    CarriersForMasterfindV2Query,
    CarriersForMasterfindV2QueryVariables
  >(CarriersForMasterfindV2Document);
  const runSearch = async (text: string): Promise<ActionDef[]> => {
    if (!canSearch) {
      return [];
    }
    const { data } = await callQuery({
      variables: { filter: { text }, first: BASE_MAX_ITEM_LIMIT },
      fetchPolicy: FETCH_POLICY,
    });
    const rawRes =
      data.carriersNullableV2?.edges.map((edge): ActionDef => {
        const label = edge.node.name;
        return {
          name: label,
          label: (): ReactNode => {
            return <CarrierPickerItemDisplay carrier={edge.node} />;
          },
          searchVector: label,
          icon: 'truckMoving',
          linkProps: getLinkProps(`/carriers/${edge.node.id}/capacity-manager`),
          id: edge.node.id,
          actionType: 'Select Record',
          objectType: 'Carrier',
        };
      }) || [];
    const res = uniqBy(rawRes, (obj) => obj.id);
    return addSectionTitleToResults(res, {
      id: 'app-carriers-title',
      name: 'Carriers',
      disabled: true,
      searchVector: 'Carriers',
    });
  };
  return useCallback(runSearch, [callQuery, canSearch]);
};

export const LoadV2LabelDisplay: FC<{
  node: SeerLoadMasterfindFragment | MasterfindLoadV3Fragment;
  filterVal: string;
  noPrefix?: boolean;
}> = ({ node, filterVal, noPrefix, ...rest }) => {
  let prefix = `Order #${node.orderNumber}`;
  const foundLoadCode = (node.loadCode || '').toString().includes(filterVal);
  if (foundLoadCode) {
    prefix = `Load #${node.loadCode}`;
  }
  const foundRouteCode = (node.routeCode || '').includes(filterVal);
  if (foundRouteCode) {
    prefix = `Route #${node.routeCode}`;
  }
  const foundRef = (node?.refNumber || '')
    .toLocaleLowerCase()
    .includes(filterVal.toLocaleLowerCase());
  if (foundRef) {
    const foundRefSingle = node.refNumber
      ?.split(',')
      .find((ref) => ref.includes(filterVal));
    prefix = `Ref #${foundRefSingle ?? node.refNumber}`;
  }
  const originAddress = getAddressDisplayString({
    value: { city: node.originCity || '', state: node.originState || '' },
    city: true,
    state: true,
  });
  const destinationAddress = getAddressDisplayString({
    value: {
      city: node.destinationCity || '',
      state: node.destinationState || '',
    },
    city: true,
    state: true,
  });
  let pickupDate = '';
  if (node.puStartDate) {
    pickupDate = getDatetimeValue({
      value: node.puStartDate,
      timezone: 'UTC',
      dateOnly: true,
    });
  }
  const addressDisplay = compact([originAddress, destinationAddress]).join(
    ' → '
  );
  const restLabel = compact([node.customer, pickupDate, addressDisplay]).join(
    ' | '
  );
  return (
    <div {...rest}>
      {!noPrefix && (
        <>
          <strong>{prefix}</strong>
          {pipeSeparator}
        </>
      )}
      {restLabel}
    </div>
  );
};

const useLoadSearchV2 = (): ((
  filter: string,
  expandSearch: boolean,
  isNumericSearchValue: boolean
) => Promise<ActionDef[]>) => {
  const callQuery = useLazyQueryWithDataPromise<
    LoadsForMasterfindV3Query,
    LoadsForMasterfindV3QueryVariables
  >(LoadsForMasterfindV3Document);

  const enableExpandSearchView =
    useFlagMe121162FeatEnableMasterfindExpandSearchView();

  const enableCollapsedLoadDrawer =
    useFlagMe276723EnableMasterfindLoadBottomTabsDrawerCollapsed();

  const runSearch = async (
    filterVal: string,
    expandSearch: boolean,
    isNumericSearchValue: boolean
  ): Promise<ActionDef[]> => {
    if (!filterVal?.length) {
      return [];
    }
    const filter = {
      value: !enableExpandSearchView
        ? `%${filterVal}%`
        : expandSearch
        ? `%${filterVal}%`
        : `${filterVal}%`,
      operator: SeerGenericStringOperator.Like,
      orGroup: '1',
    };

    // We use a non-matching filter instead of omitting the filter because at time of writing, the API required us to send all filters even if we don't want to search by a particular vector.
    const nonMatchingFilter = {
      value: 'gd45re546fbnf566bbdfr4',
      operator: SeerGenericStringOperator.Equal,
      orGroup: '1',
    };

    const { data } = await callQuery({
      variables: !enableExpandSearchView
        ? {
            filter: {
              loadCode: [filter],
              orderNumber: [filter],
              routeCode: [filter],
              refNumber: [filter],
            },
            pagination: { first: BASE_MAX_ITEM_LIMIT },
            orderBy: {
              loadCode: { order: SeerGenericOrder.Desc, position: 0 },
              orderNumber: { order: SeerGenericOrder.Desc, position: 1 },
              routeCode: { order: SeerGenericOrder.Desc, position: 2 },
              refNumber: { order: SeerGenericOrder.Desc, position: 3 },
            },
          }
        : {
            filter: isNumericSearchValue
              ? {
                  loadCode: [filter],
                  orderNumber: [filter],
                  routeCode: [filter],
                  refNumber: [filter],
                }
              : {
                  refNumber: [filter],
                  loadCode: [nonMatchingFilter],
                  orderNumber: [nonMatchingFilter],
                  routeCode: [nonMatchingFilter],
                },
            pagination: { first: BASE_MAX_ITEM_LIMIT },
            orderBy: isNumericSearchValue
              ? {
                  loadCode: { order: SeerGenericOrder.Desc, position: 0 },
                  orderNumber: { order: SeerGenericOrder.Desc, position: 1 },
                  routeCode: { order: SeerGenericOrder.Desc, position: 2 },
                  refNumber: { order: SeerGenericOrder.Desc, position: 3 },
                }
              : { refNumber: { order: SeerGenericOrder.Desc, position: 3 } },
          },
      fetchPolicy: FETCH_POLICY,
    });

    const edges = data.seerSearchMasterFindLoadSearch?.edges;

    const res =
      edges?.map((edge): ActionDef => {
        return {
          label: (): ReactElement => (
            <LoadV2LabelDisplay node={edge.node} filterVal={filterVal} />
          ),
          icon: 'boxOpen',
          action: (): void => {
            openNewWindow({
              to: `/loads/${edge.node.id}/?${
                enableCollapsedLoadDrawer ? `bottom=0&` : ''
              }row=0&section=order`,
            });
          },
          searchVector: filterVal,
          id: edge.node.id || '',
          actionType: 'Select Record',
          objectType: 'Load',
        };
      }) || [];
    return uniqBy(res, (obj) => obj.id);
  };
  return useCallback(runSearch, [
    callQuery,
    enableExpandSearchView,
    enableCollapsedLoadDrawer,
  ]);
};

const ITEM_PADDING = 10;

const SectionHeader: FC = ({ children }) => {
  const theme = useTheme();
  return (
    <div
      className={FS_UNMASK}
      css={{
        color: theme.gray[60],
        textTransform: 'uppercase',
        fontSize: '.8em',
        paddingLeft: ITEM_PADDING + 2,
      }}
    >
      {children}
    </div>
  );
};

const RenderItemLabel: FC<ActionDef> = (item: ActionDef) => {
  const theme = useTheme();
  if (item.disabled) {
    if (item.name === 'ExpandSearch Action') {
      return <Fragment>{item.label && item.label({ item, theme })}</Fragment>;
    } else {
      return <SectionHeader>{item.name}</SectionHeader>;
    }
  } else if (item.icon) {
    return (
      <Grid
        xs="20px 1fr"
        gap={0.2}
        className={item.isTitle ? FS_UNMASK : undefined}
        css={{
          paddingLeft: ITEM_PADDING,
        }}
      >
        <div>
          <Icon i={item.icon} size={'sm'} color="text" />
        </div>
        {item.label ? item.label({ item, theme }) : item.name}
      </Grid>
    );
  }
  return <Fragment>{item.name}</Fragment>;
};

const fuzzyItems = (val: string, items: ActionDef[]): ActionDef[] => {
  return fuzzy
    .filter(val, items, { extract: (obj) => obj.searchVector })
    .map((result) => result.original);
};

const addSectionTitleToResults = (
  arr: ActionDef[],
  title: ActionDef
): ActionDef[] => {
  if (!arr.length) {
    return [];
  }
  return [{ ...title, isTitle: true }].concat(arr as fixMe);
};

const useGetCombinedLoadSearchV2 = (): ((
  val: string,
  expandSearch: boolean,
  isNumericSearchValue: boolean
) => Promise<ActionDef[]>) => {
  const canSearch = useHasPermission('load:view');
  const searchLoads = useLoadSearchV2();
  return useCallback(
    async (
      rawVal: string,
      expandSearch: boolean,
      isNumericSearchValue: boolean
    ): Promise<ActionDef[]> => {
      if (!canSearch) {
        return [];
      }
      const loadResults = await searchLoads(
        rawVal,
        expandSearch,
        isNumericSearchValue
      );
      return addSectionTitleToResults(loadResults, {
        id: 'app-loads-title',
        name: 'Loads',
        disabled: true,
        searchVector: 'Loads',
        objectType: 'Load',
      });
    },
    [canSearch, searchLoads]
  );
};

const useFacilitiesSearch = (): ((val: string) => Promise<ActionDef[]>) => {
  const canSearch = useHasPermission('facility:view');
  const searchFacilities = useFacilityCombinedSearch({
    filterInactive: false,
  });
  return useCallback(
    async (val: string): Promise<ActionDef[]> => {
      if (!canSearch) {
        return [];
      }
      const res = await searchFacilities(val);
      const arr = res.map(
        (obj): ActionDef => ({
          name: obj.name,
          id: obj.id,
          searchVector: obj.name,
          icon: 'industry',
          linkProps: getLinkProps(`/facilities/${obj.id}/`),
          label: (): ReactNode => <FacilityPickerItemDisplay facility={obj} />,
          actionType: 'Select Record',
          objectType: 'Facility',
        })
      );
      return addSectionTitleToResults(arr, {
        id: getActionId('app-facilities-title'),
        name: 'Facilities',
        disabled: true,
        searchVector: 'Facilities',
      });
    },
    [canSearch, searchFacilities]
  );
};

const useCustomersSearch = (): ((val: string) => Promise<ActionDef[]>) => {
  const canSearch = useHasPermission('customer:view');
  const searchCustomers = useCustomerSearch();
  return useCallback(
    async (val: string): Promise<ActionDef[]> => {
      if (!canSearch) {
        return [];
      }
      const res = await searchCustomers(val);
      const arr = res.map(
        (obj): ActionDef => ({
          name: obj.name,
          id: obj.id,
          searchVector: obj.name,
          icon: 'building',
          linkProps: getLinkProps(`/customers/${obj.id}/`),
          label: (): ReactNode => <CustomerPickerItemDisplay customer={obj} />,
          actionType: 'Select Record',
          objectType: 'Customer',
        })
      );
      return addSectionTitleToResults(arr, {
        id: getActionId('app-customers-title'),
        name: 'Customers',
        disabled: true,
        searchVector: 'Customers',
      });
    },
    [searchCustomers, canSearch]
  );
};

const usePowersSearch = (): ((val: string) => Promise<ActionDef[]>) => {
  const canSearch = useHasPermission('power:view');
  const searchPowers = useSearchPowerPromise();
  return useCallback(
    async (val: string): Promise<ActionDef[]> => {
      if (!canSearch) {
        return [];
      }
      const res = await searchPowers(val);
      const arr = res.map(
        (obj): ActionDef => ({
          name: obj.code,
          id: obj.id,
          searchVector: obj.code,
          icon: 'truckFlatbed',
          linkProps: getLinkProps(`/powers/${obj.id}/operations`),
          label: (): ReactNode => <RenderPowerSearchItem item={obj} />,
          actionType: 'Select Record',
          objectType: 'Power',
        })
      );
      return addSectionTitleToResults(arr, {
        id: getActionId('app-powers-title'),
        name: 'Powers',
        disabled: true,
        searchVector: 'Powers',
      });
    },
    [searchPowers, canSearch]
  );
};

const useTrailersSearch = (): ((val: string) => Promise<ActionDef[]>) => {
  const canSearch = useHasPermission('trailer:view');
  const searchTrailers = useSearchTrailerPromise();
  const { getOptionById: getTrailerTypeById } = useTenantConfig(
    MinionConfigTypes.TrailerType
  );
  const { getOptionById: getOperatingStatusById } = useTenantConfig(
    MinionConfigTypes.TrailerOperatingStatus
  );

  const { getOptionById: getTrailerServiceStatusById } = useTenantConfig(
    MinionConfigTypes.TrailerServiceStatus
  );

  return useCallback(
    async (val: string): Promise<ActionDef[]> => {
      if (!canSearch) {
        return [];
      }
      const res = await searchTrailers(val);
      const arr = res.map(
        (obj): ActionDef => ({
          name: obj.code,
          id: obj.id,
          searchVector: obj.code,
          icon: 'truckMoving',
          linkProps: getLinkProps(`/trailers/${obj.id}/operations`),
          label: (): ReactNode => (
            <RenderTrailerSearchItem
              item={obj}
              getTermById={getTrailerTypeById}
              getOperatingStatusById={getOperatingStatusById}
              getTrailerServiceStatusById={getTrailerServiceStatusById}
            />
          ),
          actionType: 'Select Record',
          objectType: 'Trailer',
        })
      );
      return addSectionTitleToResults(arr, {
        id: getActionId('app-trailers-title'),
        name: 'Trailers',
        disabled: true,
        searchVector: 'Trailers',
      });
    },
    [
      canSearch,
      searchTrailers,
      getTrailerTypeById,
      getOperatingStatusById,
      getTrailerServiceStatusById,
    ]
  );
};

const useDriversSearch = (): ((val: string) => Promise<ActionDef[]>) => {
  const canSearch = useHasPermission('driver:view');
  const searchDrivers = useSearchDriverPromise();
  return useCallback(
    async (val: string): Promise<ActionDef[]> => {
      if (!canSearch) {
        return [];
      }
      const res = await searchDrivers(val);
      const arr = res.map(
        (obj): ActionDef => ({
          name: obj.code ?? '',
          id: obj.id,
          searchVector: obj.code ?? '',
          icon: 'user',
          linkProps: getLinkProps(`/drivers/${obj.id}/operations`),
          label: (): ReactNode => <RenderDriverSearchItem item={obj} />,
          actionType: 'Select Record',
          objectType: 'Driver',
        })
      );
      return addSectionTitleToResults(arr, {
        id: getActionId('app-drivers-title'),
        name: 'Drivers',
        disabled: true,
        searchVector: 'Drivers',
      });
    },
    [searchDrivers, canSearch]
  );
};

/** @deprecated This version  transforms "Al's Toy Barn" to "Al" which is incorrect */
const oldRemoveNonAlphaNumericKeepSpacesRegex = /(?!(\w|\s)).*/gi;

const useGetAllData = (): ((
  val: string,
  expandSearch: boolean,
  isNumericSearchValue: boolean
) => Promise<ActionDef[]>) => {
  const searchCarriersV2 = useCarriersSearchV2();
  const searchLoadsV2 = useGetCombinedLoadSearchV2();
  const searchFacilities = useFacilitiesSearch();
  const searchCustomers = useCustomersSearch();
  const searchPowers = usePowersSearch();
  const searchTrailers = useTrailersSearch();
  const searchDrivers = useDriversSearch();

  const flagRegex = useFlagMe32857AppMasterfindRegex();
  const showAssetObjects = useFlagMe248326MasterfindSearchForAssetObjects();
  const regexToUse = useMemo(() => {
    return (
      (flagRegex ? new RegExp(flagRegex, 'gi') : '') ||
      oldRemoveNonAlphaNumericKeepSpacesRegex
    );
  }, [flagRegex]);

  const useSearch = async (
    rawVal: string,
    expandSearch: boolean,
    isNumericSearchValue: boolean
  ): Promise<ActionDef[]> => {
    if (rawVal.length < 3) {
      return [];
    }
    const val = (rawVal || '').replaceAll(regexToUse, '');
    const results = await Promise.allSettled(
      compact([
        searchCarriersV2(val),
        searchCustomers(val),
        searchLoadsV2(val, expandSearch, isNumericSearchValue),
        showAssetObjects && searchDrivers(val),
        showAssetObjects && searchTrailers(val),
        showAssetObjects && searchPowers(val),
        searchFacilities(val),
      ])
    );
    return uniqBy(
      flattenDeep(filterByPromiseFulfilled(results).map((res) => res.value)),
      (obj) => obj.id
    );
  };
  return useCallback(useSearch, [
    regexToUse,
    showAssetObjects,
    searchCarriersV2,
    searchPowers,
    searchTrailers,
    searchDrivers,
    searchFacilities,
    searchCustomers,
    searchLoadsV2,
  ]);
};

interface Expandprops {
  setExpandSearch: (value: boolean) => void;
  setShowExpandSearch: (value: boolean) => void;
  noItems: boolean;
  handleExpandSearchEvent?: () => void;
}
const ExpandSearchView: FC<Expandprops> = ({
  setExpandSearch,
  setShowExpandSearch,
  noItems,
  handleExpandSearchEvent,
}) => {
  return (
    <Grid
      css={{
        justifyItems: 'center',
        bottom: 0,
        position: 'relative',
        margin: noItems ? '100px 0' : 0,
        borderTop: !noItems ? `2px solid ${PRIVATE_themeObj.gray[80]}` : 'none',
      }}
    >
      <div css={{ fontSize: 14, padding: '17px' }}>
        <Tooltip
          label={
            <text>
              By default Masterfind searches for exact matches at the beginning
              of the string.
              <br />
              Expanding the search will reach for matches that contain the
              search string.
            </text>
          }
          css={{ zIndex: MODAL_BACKGROUND + 1 }}
        >
          <div>
            Didn&apos;t find what you&apos;re looking for?{' '}
            <Icon i="question" size={'sm'} color="text" />
          </div>
        </Tooltip>

        <a
          href=""
          css={{
            margin: 30,
            color: `${PRIVATE_themeObj.colors.primary} !important`,
          }}
          data-testid="expand-search"
          onClick={(event): void => {
            event.preventDefault();
            event.stopPropagation();
            setExpandSearch(true);
            setShowExpandSearch(false);
            handleExpandSearchEvent?.();
          }}
        >
          Expand your search
          <Icon
            i="arrowRightSolid"
            size="sm"
            color="primary"
            css={{ marginLeft: '6px', marginTop: '10px' }}
          />
        </a>
      </div>{' '}
    </Grid>
  );
};

const TITLE_HEIGHT = 36;
const TOTAL_HEIGHT = 350;
const SCROLLER_HEIGHT = TOTAL_HEIGHT - 48 - TITLE_HEIGHT;
const MASTERFIND_ELEMENT_ID = 'masterfind';

export const Masterfind: FC = () => {
  const permissionsContext = usePermissionsContext();
  const { masterFindGlobalService } = useContext(MasterFindGlobalStateContext);
  const [state] = useActor(masterFindGlobalService);
  const { send: sendMasterFind } = masterFindGlobalService;
  const [current, send] = useMachine(mfMachine);
  const [currentInputValue, setCurrentInputValue] = useState('');
  useShortcut({
    keys: 'ctrl+p, cmd+p, ctrl+shift+p, cmd+shift+p',
    debounceMillis: 20,
    onEvent: () => {
      send('TOGGLE');
      fsEvent(FullstoryEventTypes.MASTERFIND, {
        action: 'Open via Keyboard',
      });
    },
  });
  const { card, dialog, gray, hr, colors } = useTheme();
  const [expandSearch, setExpandSearch] = useState<boolean>(false);
  const [showExpandSearch, setshowExpandSearch] = useState<boolean>(false);
  const [isExpandedSearch, setIsExpandedSearch] = useState<boolean>(false);
  const [isNumericSearchValue, setisNumericSearchValue] =
    useState<boolean>(false);
  const { actions: defaultActions } = useContext(MasterfindContext);
  const getData = useGetAllData();

  const [stateItems, setItems] = useState<ActionDef[]>([]);

  const waitTime = useFlagMe35600FeatMasterfindDebounceMs();
  const enableExpandSearchView =
    useFlagMe121162FeatEnableMasterfindExpandSearchView();

  const filteredDefaultActions = currentInputValue
    ? fuzzyItems(currentInputValue, defaultActions)
    : defaultActions;

  let defaultActionsToRender: ActionDef[] = [];

  if (filteredDefaultActions.length) {
    defaultActionsToRender = [defaultActionsTitle].concat(
      filteredDefaultActions
    );
  }

  const computedItems = currentInputValue
    ? defaultActionsToRender.concat(stateItems)
    : defaultActionsToRender;

  const items = (
    current.matches('active.default') ? defaultActionsToRender : computedItems
  ).filter((obj) => !obj.hidden && obj.visible !== false);

  const noItems = items.length === 0;

  const titleColor = gray[10];

  const [currentlyRunningDataFetch, setCurrentlyRunningDataFetch] = useState<{
    id: string;
    prom: Cancelable<ActionDef[]> | null;
  } | null>(null);

  const onInputChange = useDebouncedFn(
    async (
      val: string,
      isExpandSearch: boolean,
      isNumericSearchValue: boolean
    ) => {
      const id = uniqueId('mf-data');
      if (val.length < 3) {
        return;
      }
      send('LOAD_START');
      try {
        setCurrentlyRunningDataFetch((state) => {
          state?.prom?.cancel();
          return null;
        });
        const cProm = new Cancelable<ActionDef[]>(async (resolve, reject) => {
          try {
            const res = await getData(
              val,
              isExpandSearch,
              isNumericSearchValue
            );
            const getTopLevelResponseTypes =
              filter(res, { disabled: true }) || [];
            const resultObjects =
              filter(res, (obj) => obj?.disabled !== true) || [];
            fsEvent(FullstoryEventTypes.MASTERFIND, {
              action: 'Search',
              charactersSearched: val?.toString().length || 0,
              numResults: isArray(resultObjects) ? resultObjects.length : 0,
              resultTypes: map(getTopLevelResponseTypes, 'name'),
            });
            resolve(res);
          } catch (err: anyOk) {
            reject(err);
          }
        });
        setCurrentlyRunningDataFetch(() => ({ id, prom: cProm }));
        const data = await cProm;
        if (enableExpandSearchView && !isExpandSearch && data.length) {
          const expanSearchItem: ActionDef = {
            id: 'expand-search-view',
            name: 'ExpandSearch Action',
            disabled: true,
            searchVector: '',
            label: (): ReactNode => (
              <ExpandSearchView
                setExpandSearch={setExpandSearch}
                setShowExpandSearch={setshowExpandSearch}
                noItems={noItems}
                handleExpandSearchEvent={handleExpandSearchEvent}
              />
            ),
          };
          setItems([...data, expanSearchItem]);
        } else {
          setItems(data);
        }
        setCurrentlyRunningDataFetch(() => ({ id, prom: null }));
      } catch {
        // noop
      }
    },
    waitTime,
    [setCurrentlyRunningDataFetch]
  );

  const resetItemsOnInputChange = (): void => {
    if (stateItems.findIndex(({ id }) => id === 'expand-search-view') !== -1) {
      setItems((stateItems.slice(0, -1) || []) as ActionDef[]);
      return;
    } else {
      return;
    }
  };
  const prev = usePrevious(currentInputValue);

  useEffect(() => {
    if (state?.matches('active.default')) {
      send('TOGGLE');
    }
  }, [send, state]);

  useEffect(() => {
    if (prev !== currentInputValue && currentInputValue) {
      if (enableExpandSearchView) {
        resetItemsOnInputChange();
        setshowExpandSearch(false);
        if (!isNaN(Number(currentInputValue))) {
          setisNumericSearchValue(true);
          onInputChange(currentInputValue, expandSearch, true);
        } else {
          setisNumericSearchValue(false);
          onInputChange(currentInputValue, expandSearch, false);
        }
      } else {
        onInputChange(currentInputValue, expandSearch, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInputValue, onInputChange, prev]);

  useEffect(() => {
    if (enableExpandSearchView && expandSearch && currentInputValue) {
      resetItemsOnInputChange();
      send('INPUT_CHANGE');
      setIsExpandedSearch(true);
      onInputChange(currentInputValue, expandSearch, isNumericSearchValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandSearch]);

  useEffect(() => {
    if (currentlyRunningDataFetch?.prom === null) {
      send('LOAD_COMPLETE');
      if (enableExpandSearchView) {
        if (expandSearch) {
          setExpandSearch(false);
        }
        if (!isExpandedSearch) {
          setshowExpandSearch(true);
        } else {
          setIsExpandedSearch(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlyRunningDataFetch, send]);

  const filteredItemsViaPerms = items.filter((obj) => {
    if (!obj.permissions) {
      return obj;
    }
    return getResolvedPermissionValues({
      permissionsContext,
      scopes: obj.permissions,
    }).every(Boolean);
  });

  const itemsForAutocomplete = filteredItemsViaPerms.map((obj) => {
    return {
      label: (): ReactNode => <RenderItemLabel {...obj} />,
      value: obj,
      id: obj.id || obj.name,
      disabled: obj.disabled,
    };
  });

  const close = (): void => {
    currentlyRunningDataFetch?.prom?.cancel();
    send('CLOSE');
    sendMasterFind('CLOSE');
    setCurrentInputValue('');
    setshowExpandSearch(false);
    triggerFsCloseEvent();
  };

  const isLoading =
    current.matches('loading.stage0') ||
    current.matches('loading.stage1') ||
    current.matches('loading.stage2');

  function createFsPayload(actionType: string): FsPayloadEventDef {
    const getTopLevelResponseTypes =
      filter(stateItems, { disabled: true }) || [];
    const resultObjects =
      filter(stateItems, (obj) => obj?.disabled !== true) || [];
    const payload: FsPayloadEventDef = {
      action: actionType,
      charactersSearched: currentInputValue?.toString()?.length || null,
      resultTypes:
        isArray(getTopLevelResponseTypes) && getTopLevelResponseTypes.length > 0
          ? compact(map(getTopLevelResponseTypes, 'searchVector'))
          : null,
      numResults: resultObjects?.length || null,
    };
    return omitBy(payload, (value) => value === null) as fixMe;
  }

  function triggerFsCloseEvent(): void {
    fsEvent(FullstoryEventTypes.MASTERFIND, createFsPayload('Close'));
  }

  const handleExpandSearchEvent = useCallback(() => {
    fsEvent(FullstoryEventTypes.MASTERFIND, createFsPayload('Expand Search'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingMessage = current.matches('loading.error')
    ? 'Could not load search results. Try again later.'
    : '';

  return (
    <DialogOverlay
      css={[
        dialog.overlay,
        {
          display: 'grid',
          placeItems: 'center',
          zIndex: MODAL_BACKGROUND,
          background: 'none',
        },
      ]}
      onDismiss={(): void => {
        close();
      }}
      isOpen={current.value !== 'inactive'}
    >
      <DialogContent
        css={{
          padding: 0,
          margin: '-20vh 0 0 0',
          position: 'relative',
          overflow: 'visible',
          fontSize: FONT_SIZE,
          width: '50vw',
          minWidth: 600,
          maxWidth: '90vw',
        }}
      >
        <form
          id={MASTERFIND_ELEMENT_ID}
          onSubmit={(e): void => e.preventDefault()}
          spellCheck="false"
          data-loading={isLoading.toString()}
          css={{
            border: `3px solid ${titleColor}`,
            borderRadius: 3,
            background: titleColor,
            height: TOTAL_HEIGHT,
            boxShadow: '0 2px 10px rgba(0,0,0,0.15)',
            '[role="listbox"]': {
              margin: 0,
              maxHeight: 'unset',
              height: SCROLLER_HEIGHT,
              boxShadow: 'none !important',
              border: 0,
              borderRadius: 3,
              fontSize: 14,
            },
            input: {
              border: 0,
              borderBottom: `2px solid ${hr.background}`,
              borderRadius: '3px 3px 0 0',
              '&:focus': {
                outline: '0 !important',
              },
            },
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 3,
              background: card.background,
              overflow: 'hidden',
              height: '100%',
              borderTop: `6px solid ${titleColor}`,
            }}
          >
            <Grid
              className={FS_UNMASK}
              css={{
                color: card.background,
                textTransform: 'uppercase',
                letterSpacing: '.3em',
                alignItems: 'center',
                fontSize: 12,
                fontWeight: 700,
                height: TITLE_HEIGHT,
                background: titleColor,
                marginTop: -6,
              }}
              gap={0.2}
              xs="min-content 1fr"
            >
              <Logo css={{ width: TITLE_HEIGHT, height: TITLE_HEIGHT }} />
              Masterfind
            </Grid>
            <AutoComplete<ActionDef>
              loading={loadingMessage}
              disableSelectOnTab
              showSearchIcon
              isOpen
              // We want to default highlight the 2nd item in the list, because the first will always be a section header.
              defaultHighlightedIndex={1}
              itemToString={(item) => (item?.id as string) ?? ''}
              inputProps={{
                autoFocus: true,
                placeholder: 'Enter a command or search',
              }}
              css={{
                fontSize: FONT_SIZE,
                input: { fontSize: FONT_SIZE, paddingLeft: FONT_SIZE + 16 },
                '[role="combobox"] > [data-icon="search"]': {
                  fontSize: Math.round(FONT_SIZE * 0.9),
                  color: colors.text,
                },
                'li[data-disabled="true"]': {
                  cursor: 'default',
                },
                '[aria-selected=true] svg': {
                  color: `${gray.white} !important`,
                },
                'ul[role="listbox"]':
                  noItems || isExpandedSearch ? { display: 'none' } : {},
              }}
              downshiftStateReducer={(state, changes: fixMe): fixMe => {
                if (
                  changes.highlightedIndex !== state.highlightedIndex &&
                  changes.highlightedIndex === 0
                ) {
                  // Without this, the first SectionHeader will get lost if the user arrives at the top item somehow (like arrowing from the last item in the list, down, back to the first)
                  setTimeout(() => {
                    document
                      .querySelector(
                        `#${MASTERFIND_ELEMENT_ID} [role="listbox"]`
                      )
                      ?.scrollTo(0, 0);
                  }, 0);
                }
                switch (changes.type) {
                  case stateTypes.changeInput:
                  case stateTypes.keyDownEscape:
                    setCurrentInputValue(changes.inputValue);
                    break;
                  default:
                    changes.inputValue = state.inputValue;
                }
                switch (changes.type) {
                  case stateTypes.keyDownEscape:
                    if (!state.inputValue) {
                      close();
                    } else {
                      send('RESET');
                    }
                    break;
                  case stateTypes.changeInput:
                    if (!changes.inputValue) {
                      send('RESET');
                    } else {
                      send('INPUT_CHANGE');
                    }
                    break;
                }
                return changes;
              }}
              items={itemsForAutocomplete}
              onChange={(item): void => {
                if (item && item.value) {
                  const masterfindDataObject = {
                    ...createFsPayload(item?.value.actionType || ''),
                    outcomeType:
                      item?.value?.objectType || item?.value?.name || null,
                  };
                  fsEvent(
                    FullstoryEventTypes.MASTERFIND,
                    omitBy(masterfindDataObject, (value) => value === null)
                  );
                  const { action } = item.value;
                  if (action) {
                    action();
                  } else if (item.value.linkProps) {
                    if (win.location.search.match('header=0')) {
                      // TODO: may want to re-enable this if we want to use the concept of a single 'main' window
                      // if (IS_ELECTRON) {
                      //   return (history as fixMe).pushMain(
                      //     item.value.linkProps.to
                      //   );
                      // }
                      openNewWindow(item.value.linkProps);
                    } else if (!IS_HEADER_ALLOWED) {
                      openNewWindow(item.value.linkProps);
                    } else {
                      history.push(item.value.linkProps.to);
                    }
                  }
                  close();
                }
              }}
            />
            <LoadingBar
              loading={isLoading}
              targetMillis={3000}
              css={{
                zIndex: MODAL_BACKGROUND + 1,
                marginTop: (DEFAULT_LOADING_BAR_HEIGHT / 2) * -1,
                position: 'relative',
              }}
            />
            {!showExpandSearch &&
              noItems &&
              current.matches('active.loaded') && (
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 15,
                    fontSize: 13,
                    padding: '17px',
                    color: gray[99],
                    margin: '55px 0',
                  }}
                >
                  <Icon
                    i="search"
                    size="xl"
                    color="text"
                    css={{
                      pointerEvents: 'none',
                    }}
                  />
                  <div css={{ fontSize: 20 }}>No results found</div>
                  <div>
                    Try adjusting your search to find what you&apos;re looking
                    for.
                  </div>
                </div>
              )}
            {isExpandedSearch && isLoading && (
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  fontSize: 13,
                  padding: '17px',
                  color: gray[99],
                  margin: '100px 0',
                }}
              >
                <div>Expanding your search...</div>
              </div>
            )}
            {enableExpandSearchView && showExpandSearch && noItems && (
              <ExpandSearchView
                setExpandSearch={setExpandSearch}
                setShowExpandSearch={setshowExpandSearch}
                noItems={noItems}
              />
            )}
          </div>
        </form>
      </DialogContent>
    </DialogOverlay>
  );
};
