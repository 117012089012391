export type PermissionsScopeNames =
  | 'accounting:advance:activate'
  | 'accounting:advance:approve'
  | 'accounting:advance:block'
  | 'accounting:advance:cancel'
  | 'accounting:advance:issue'
  | 'accounting:bulkRerating:edit'
  | 'accounting:bulkRerating:view'
  | 'accounting:costDetail:VendorFinancialStatusAwaitingPaperwork:create'
  | 'accounting:costDetail:VendorFinancialStatusAwaitingPaperwork:edit'
  | 'accounting:costDetail:VendorFinancialStatusComplete:create'
  | 'accounting:costDetail:VendorFinancialStatusComplete:edit'
  | 'accounting:costDetail:VendorFinancialStatusException:create'
  | 'accounting:costDetail:VendorFinancialStatusException:edit'
  | 'accounting:costDetail:VendorFinancialStatusIncomplete:create'
  | 'accounting:costDetail:VendorFinancialStatusIncomplete:edit'
  | 'accounting:costDetail:VendorFinancialStatusNone:create'
  | 'accounting:costDetail:VendorFinancialStatusNone:edit'
  | 'accounting:costDetail:VendorFinancialStatusSettled:create'
  | 'accounting:costDetail:VendorFinancialStatusUnmapped:create'
  | 'accounting:costDetail:VendorFinancialStatusUnmapped:edit'
  | 'accounting:costDetail:vendorFinancialStatusExternal:create'
  | 'accounting:costDetail:vendorFinancialStatusExternal:edit'
  | 'accounting:costDetail:VendorFinancialStatusVouchered:create'
  | 'accounting:costDetail:VendorFinancialStatusVouchered:edit'
  | 'accounting:costDetail:VendorFinancialStatusAwaitingRequirements:create'
  | 'accounting:costDetail:VendorFinancialStatusAwaitingRequirements:edit'
  | 'accounting:driverCostDetail:vendorFinancialStatusAwaitingRequirements:edit'
  | 'accounting:driverCostDetail:vendorFinancialStatusComplete:edit'
  | 'accounting:driverCostDetail:vendorFinancialStatusIncomplete:edit'
  | 'accounting:driverCostDetail:vendorFinancialStatusNone:edit'
  | 'accounting:driverCostDetail:vendorFinancialStatusSettled:edit'
  | 'accounting:driverCostDetail:vendorFinancialStatusVouchered:edit'
  | 'accounting:driverCostDetail:view'
  | 'accounting:costDetail:view'
  | 'accounting:customerCreditMemo:create'
  | 'accounting:customerCreditMemo:process'
  | 'accounting:customerInvoice:create'
  | 'accounting:customerInvoice:view'
  | 'accounting:customerOrderRequiredDocuments:edit'
  | 'accounting:customerOrderRequiredDocuments:waive'
  | 'accounting:customerOrderRequiredDocuments:associate'
  | 'accounting:customerOrderRequiredDocuments:disassociate'
  | 'accounting:customerSettlement:edit'
  | 'accounting:customerSettlement:view'
  | 'accounting:invoiceBatch:override'
  | 'accounting:invoiceReqBypass:edit'
  | 'accounting:invoiceReqBypass:view'
  | 'accounting:invoices:view'
  | 'accounting:rateDetail:OrderFinancialStatusAwaitingPaperwork:create'
  | 'accounting:rateDetail:OrderFinancialStatusAwaitingPaperwork:edit'
  | 'accounting:rateDetail:OrderFinancialStatusComplete:create'
  | 'accounting:rateDetail:OrderFinancialStatusComplete:edit'
  | 'accounting:rateDetail:OrderFinancialStatusException:create'
  | 'accounting:rateDetail:OrderFinancialStatusException:edit'
  | 'accounting:rateDetail:OrderFinancialStatusIncomplete:create'
  | 'accounting:rateDetail:OrderFinancialStatusIncomplete:edit'
  | 'accounting:rateDetail:OrderFinancialStatusInvoiced:create'
  | 'accounting:rateDetail:OrderFinancialStatusInvoiced:edit'
  | 'accounting:rateDetail:OrderFinancialStatusNone:create'
  | 'accounting:rateDetail:OrderFinancialStatusNone:edit'
  | 'accounting:rateDetail:OrderFinancialStatusSettled:create'
  | 'accounting:rateDetail:OrderFinancialStatusUnmapped:create'
  | 'accounting:rateDetail:OrderFinancialStatusUnmapped:edit'
  | 'accounting:rateDetail:OrderFinancialStatusExternal:create'
  | 'accounting:rateDetail:OrderFinancialStatusExternal:edit'
  | 'accounting:rateDetail:OrderFinancialStatusAwaitingRequirements:create'
  | 'accounting:rateDetail:OrderFinancialStatusAwaitingRequirements:edit'
  | 'accounting:rateDetail:view'
  | 'accounting:ratingSource:edit'
  | 'accounting:vendorCreditMemo:create'
  | 'accounting:vendorCreditMemo:process'
  | 'accounting:vendorInvoice:create'
  | 'accounting:vendorInvoice:edit'
  | 'accounting:vendorInvoice:view'
  | 'accounting:vendorRouteRequiredDocuments:edit'
  | 'accounting:vendorRouteRequiredDocuments:associate'
  | 'accounting:vendorRouteRequiredDocuments:disassociate'
  | 'accounting:vendorRouteRequiredDocuments:waive'
  | 'accounting:vendorSettlement:edit'
  | 'accounting:vendorSettlement:view'
  | 'accounting:vendorVoucher:create'
  | 'accounting:vendorVoucher:view'
  | 'accounting:vendorVoucherAdj:edit'
  | 'accounting:vendorVoucherReqBypass:edit'
  | 'accounting:vendorVoucherReqBypass:view'
  | 'accounting:DisableRouteVendorFinancials:view'
  | 'accounting:DisableRouteVendorFinancials:edit'
  | 'accounting:DisableOrderFinancials:view'
  | 'accounting:DisableOrderFinancials:edit'
  | 'accounting:view'
  | 'accounting:voucherBatch:override'
  | 'accounting:vouchers:view'
  | 'accounting:driverVouchers:view'
  | 'accounting:driverVouchers:edit'
  | 'accounting:driverUnvouchered:view'
  | 'accounting:driverUnvouchered:edit'
  | 'accounting:otherPayItemApproval:edit'
  | 'accounting:invoiceHoldApproval:edit'
  | 'accounting:voucherHoldApproval:edit'
  | 'accounting:payProgramAssignment:edit'
  | 'accounting:driverCard:Comdata:view'
  | 'admin:accounting:edit'
  | 'admin:accounting:view'
  | 'admin:clientDefinedTypes:edit'
  | 'admin:clientDefinedTypes:view'
  | 'admin:releaseLock:edit'
  | 'admin:releaseLock:view'
  | 'admin:vendorRequiredDocs:edit'
  | 'admin:vendorRequiredDocs:view'
  | 'admin:view'
  | 'book:availableLoads:view'
  | 'book:myMatches:view'
  | 'book:optimatch:view'
  | 'book:stateMap:view'
  | 'book:view'
  | 'carrier:accounting:chargeTypes:edit'
  | 'carrier:accounting:edit'
  | 'carrier:accounting:view'
  | 'carrier:accounting:accessorial:view'
  | 'carrier:accounting:accessorial:edit'
  | 'carrier:addresses:edit'
  | 'carrier:addresses:view'
  | 'carrier:BINCheckbox:edit'
  | 'carrier:BINCheckbox:view'
  | 'carrier:configurations:view'
  | 'carrier:carrierAuditsExternalStatus:view'
  | 'carrier:certifications:create'
  | 'carrier:certifications:delete'
  | 'carrier:certifications:edit'
  | 'carrier:certifications:view'
  | 'carrier:code:edit'
  | 'carrier:compliance:edit'
  | 'carrierCompliance:view'
  | 'carrier:contacts:edit'
  | 'carrier:contacts:view'
  | 'carrier:create'
  | 'carrier:edit'
  | 'carrier:externalStatus:edit'
  | 'carrier:geographies:edit'
  | 'carrier:geographies:view'
  | 'carrier:insurances:edit'
  | 'carrier:insurances:view'
  | 'carrier:identifiers:edit'
  | 'carrier:identifiers:view'
  | 'carrier:identifiers:siteid:edit'
  | 'carrier:intrastateonly:edit'
  | 'carrier:intrastateonly:view'
  | 'carrier:mainRep:edit'
  | 'carrier:reps:edit'
  | 'carrier:reps:view'
  | 'carrier:services:create'
  | 'carrier:services:delete'
  | 'carrier:services:edit'
  | 'carrier:services:view'
  | 'carrier:serviceprofile:edit'
  | 'carrier:serviceprofile:view'
  | 'carrier:status:edit'
  | 'carrier:view'
  | 'carrier:routelist:download'
  | 'carrierCostQuotes:add'
  | 'carrierCostQuotes:edit'
  | 'carrierCostQuotes:view'
  | 'carrierCRM:edit'
  | 'carrierCRM:view'
  | 'carrierCRM:Notes:Download'
  | 'carrierDocuments:create'
  | 'carrierDocuments:edit'
  | 'carrierDocuments:view'
  | 'carrierSetting:edit'
  | 'carrierSetting:view'
  | 'carrierTenderProcure:action'
  | 'carrierTenderProcure:edit'
  | 'carrierTenderProcure:view'
  | 'carrierTenderSettings:edit'
  | 'carrierTenderSettings:view'
  | 'carrier:regions:view'
  | 'carrier:regions:edit'
  | 'carrier:releaseMethods:edit'
  | 'carrier:releaseMethods:view'
  | 'carrier:dataExchange:view'
  | 'carrier:dataExchange:edit'
  | 'commissions:customerCarrierSplitOverride:edit'
  | 'containerReservations:admin'
  | 'customer:accounting:chargeTypes:edit'
  | 'customer:accounting:edit'
  | 'customer:accounting:credit:edit'
  | 'customer:accounting:view'
  | 'customer:accounting:accessorial:view'
  | 'customer:accounting:accessorial:edit'
  | 'customer:accounting:canvas:edit'
  | 'customer:accounting:canvas:view'
  | 'customer:addresses:edit'
  | 'customer:addresses:view'
  | 'customer:canvas:edit'
  | 'customer:canvas:view'
  | 'customer:customeraudit:view'
  | 'customer:contacts:edit'
  | 'customer:contacts:view'
  | 'customer:configuration:priorityQuoteType:edit'
  | 'customer:configurations:rating:edit'
  | 'customer:create'
  | 'customer:dataExchange:view'
  | 'customer:dataExchange:edit'
  | 'customer:edit'
  | 'customer:facilityRelationship:edit'
  | 'customer:facilityRelationship:view'
  | 'customer:fuel:edit'
  | 'carrier:fuel:edit'
  | 'customer:geofencing:edit'
  | 'customer:identifiers:edit'
  | 'customer:identifiers:view'
  | 'customer:identifiers:siteid:edit'
  | 'customer:insurance:edit'
  | 'customer:insurance:view'
  | 'customer:mainRep:edit'
  | 'customer:reps:edit'
  | 'customer:reps:view'
  | 'customer:trackingmethod:edit'
  | 'customer:spotquote:edit'
  | 'customer:standing:edit'
  | 'customer:standing:view'
  | 'customer:view'
  | 'customer:orderList:download'
  | 'customerCRM:edit'
  | 'customerCRM:view'
  | 'customerCRM:Notes:Download'
  | 'customerDocument:create'
  | 'customerDocument:edit'
  | 'customerDocument:view'
  | 'CustomerLoadDefaults:edit'
  | 'CustomerLoadDefaults:view'
  | 'customerTenderSettings:edit'
  | 'customerTenderSettings:view'
  | 'customerQuote:edit'
  | 'customerQuote:view'
  | 'default:no:scope'
  | 'driver:accounting:view'
  | 'driver:addresses:edit'
  | 'driver:addresses:view'
  | 'driver:create'
  | 'driver:edit'
  | 'driver:externalAccess:edit'
  | 'driver:general:view'
  | 'driver:history:view'
  | 'driver:messaging:edit'
  | 'driver:messaging:view'
  | 'driver:operations:view'
  | 'driver:operations:edit'
  | 'driver:preferences:view'
  | 'driver:personalInformation:view'
  | 'driver:personalInformation:edit'
  | 'driver:scorecard:view'
  | 'driver:targets:view'
  | 'driver:view'
  | 'driver:routelist:download'
  | 'driver:routingrequests:edit'
  | 'employee:employeeaudit:view'
  | 'employee:create'
  | 'employee:edit'
  | 'employee:group:edit'
  | 'employee:structure:view'
  | 'employee:structure:edit'
  | 'employee:region:edit'
  | 'employee:view'
  | 'facility:addresses:edit'
  | 'facility:addresses:view'
  | 'facility:facilityaudit:view'
  | 'facility:contacts:edit'
  | 'facility:contacts:view'
  | 'facility:edit'
  | 'facilityDocuments:create'
  | 'facilityDocuments:edit'
  | 'facilityDocuments:view'
  | 'facility:schedules:edit'
  | 'facility:schedules:view'
  | 'facility:directions:edit'
  | 'facility:directions:view'
  | 'facility:reps:view'
  | 'facility:reps:edit'
  | 'facility:general:holidays:edit'
  | 'facility:general:holidays:view'
  | 'facility:locations:edit'
  | 'facility:locations:view'
  | 'facility:locations:geofencing:edit'
  | 'facility:view'
  | 'facility:routelist:download'
  | 'driver:routelist:view'
  | 'gateReservations:admin'
  | 'incident:accounting:edit'
  | 'incident:claims:edit'
  | 'incident:edit'
  | 'incident:view'
  | 'lane:edit'
  | 'lane:view'
  | 'profile:view'
  | 'load:accounting:edit'
  | 'load:accounting:view'
  | 'load:audit:edit'
  | 'load:audit:view'
  | 'load:autoSchedule:edit'
  | 'load:autoSchedule:view'
  | 'load:booking:edit'
  | 'load:booking:view'
  | 'load:commissions:edit'
  | 'load:commissions:view'
  | 'load:commodities:create'
  | 'load:commodities:delete'
  | 'load:commoditiesActual:edit'
  | 'load:commoditiesPlanned:edit'
  | 'load:create'
  | 'load:creditbypasscheck:edit'
  | 'load:drivers:edit'
  | 'load:drivers:view'
  | 'load:edit'
  | 'load:map:view'
  | 'load:money:edit'
  | 'load:money:view'
  | 'load:offers:edit'
  | 'load:offers:view'
  | 'load:order:delete'
  | 'load:order:edit'
  | 'load:orderTenderStatus:edit'
  | 'load:preplan:edit'
  | 'load:ratecon:edit'
  | 'load:repeat'
  | 'load:route:addRoute'
  | 'load:route:create'
  | 'load:route:associateTrips:edit'
  | 'load:routeMaxCost:edit'
  | 'load:routePriority:edit'
  | 'load:stop:create'
  | 'load:stop:delete'
  | 'load:stop:reorder'
  | 'load:stopEvent:create'
  | 'load:stopEvent:edit'
  | 'load:stopPlanDetails:edit'
  | 'load:stopScheduleDetails:edit'
  | 'load:stopTimes:homeDepot:edit'
  | 'load:stopTimes:mizkan:edit'
  | 'load:stopTimes:target:edit'
  | 'load:track:edit'
  | 'load:track:view'
  | 'load:tracking:edit'
  | 'load:tracking:view'
  | 'load:view'
  | 'loadDocuments:edit'
  | 'loadDocuments:view'
  | 'loadPatterns:create'
  | 'loadPatterns:edit'
  | 'loadPatterns:view'
  | 'loadPatterns:delete'
  | 'loadPatterns:apply'
  | 'loadRoute:edit'
  | 'loadRoute:routeDetails:edit'
  | 'loadRoute:associatedRepsMain:edit'
  | 'loadRoute:assignedRepsMain:edit'
  | 'loadRoute:binOverride:edit'
  | 'loadRouteActivationStatus:edit'
  | 'offer:approve'
  | 'offer:book'
  | 'offer:counter'
  | 'offer:create'
  | 'offer:decline'
  | 'offer:delete'
  | 'offer:edit'
  | 'offer:view'
  | 'power:view'
  | 'routePosting:edit'
  | 'routePosting:view'
  | 'routingGuide:edit'
  | 'trailer:create'
  | 'trailer:edit'
  | 'trailer:view'
  | 'trailer:routelist:download'
  | 'truckEntry:create'
  | 'truckEntry:delete'
  | 'truckEntry:edit'
  | 'truckEntry:view'
  | 'truckEntryTemplate:create'
  | 'truckEntryTemplate:delete'
  | 'truckEntryTemplate:edit'
  | 'truckEntryTemplate:view'
  | 'customerRoeStatus:action'
  | 'carrierRoeStatus:action'
  | 'customer:regions:view'
  | 'customer:regions:edit'
  | 'customer:commitments:view'
  | 'customer:commitments:edit'
  | 'order:customer:change'
  | 'lane:deactivate:edit'
  | 'facility:loadDefaults:facilityRelationships:edit'
  | 'facility:loadDefaults:facilityRelationships:view'
  | 'dashboard:carrierManager:view'
  | 'dashboard:carrierRep:view'
  | 'dashboard:customerRep:view'
  | 'dashboard:crmRep:view'
  | 'dashboard:customerManager:view'
  | 'carrier:commitments:edit'
  | 'carrier:commitments:view'
  | 'carrier:carrierrelationships:view'
  | 'carrier:carrierrelationships:edit'
  | 'carrier:customerRelationship:edit'
  | 'carrier:configurations:rating:edit'
  | 'customerBid:edit'
  | 'customerBid:view'
  | 'customerBid:round:edit'
  | 'customerBid:bidLane:edit'
  | 'customerBid:bidQuote:edit'
  | 'customer:customerrelationships:edit'
  | 'customer:customerrelationships:view'
  | 'power:fleetrelationships:edit'
  | 'power:fleetrelationships:view'
  | 'power:operationaldetails:edit'
  | 'power:operationaldetails:view'
  | 'power:identifiers:edit'
  | 'power:identifiers:view'
  | 'power:routelist:download'
  | 'power:routelist:view'
  | 'driver:fleetrelationships:view'
  | 'driver:fleetrelationships:edit'
  | 'driver:associatedreps:view'
  | 'accounting:carrierPay:view'
  | 'accounting:carrierPay:edit'
  | 'driver:associatedreps:edit'
  | 'driver:operationaldetails:view'
  | 'accounting:driverPay:view'
  | 'accounting:driverPay:edit'
  | 'driver:operationaldetails:edit'
  | 'driver:professionalinformation:view'
  | 'driver:professionalinformation:edit'
  | 'driver:identifiers:view'
  | 'driver:identifiers:edit'
  | 'accounting:otherPayItem:view'
  | 'accounting:otherPayItem:edit'
  | 'driver:plannedtimeoff:view'
  | 'driver:plannedtimeoff:edit'
  | 'trailer:fleetrelationships:edit'
  | 'trailer:fleetrelationships:view'
  | 'trailer:operationaldetails:edit'
  | 'trailer:operationaldetails:view'
  | 'trailer:identifiers:edit'
  | 'trailer:identifiers:view'
  | 'trailer:routelist:view'
  | 'trailer:serviceStatusReasonCode:edit'
  | 'power:create'
  | 'power:edit'
  | 'power:operations:edit'
  | 'contacts:code:edit'
  | 'load:stopConfirmedAppts:edit'
  | 'load:stopDriverTargetTimes:edit'
  | 'driver:hos:view'
  | 'driver:hos:download'
  | 'trailer:customerRelationships:edit'
  | 'load:order:division:edit'
  | 'load:route:primaryDivision:edit'
  | 'load:route:additionalDivision:edit'
  | 'customer:linesOfBusiness:view'
  | 'customer:linesOfBusiness:edit'
  | 'customer:child:create'
  | 'customer:configurations:view'
  | 'customer:configurations:edit'
  | 'load:route:associateToOrder:edit'
  | 'carrier:child:create'
  | 'driverTender:create'
  | 'driverTender:respond'
  | 'driverTender:send'
  | 'load:route:payable:edit';
export type PermissionsScopeObject = {
  [k in PermissionsScopeNames]: boolean;
};

/** All scopes default to `true` for local dev unless added here as false */
export const scopesFalseForLocalDev: Partial<PermissionsScopeObject> = {
  'dashboard:carrierManager:view': false, // For cypress test case to show dialog modal.
};
